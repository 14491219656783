import { Title } from "@angular/platform-browser";
import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";
import { UserService } from "../../shared/services/user.service";
import { Role } from "../../shared/models/user.model";
import { RoutingService } from "../../shared/services/routing.service";
import { RoutingSearch } from "../../shared/models/routing.model";
import { RoutingTableComponent } from "../routing-table/routing-table.component";

@Component({
    selector: 'app-routing-page',
    templateUrl: 'routing-page.component.html'
})
export class RoutingPageComponent implements AfterViewInit {

    isAdmin = false;

    @ViewChild(RoutingTableComponent, { static: false }) table: RoutingTableComponent;

    constructor(private titleService: Title, private modal: ModalService, private userService: UserService, private routingService: RoutingService) {
        titleService.setTitle('Routing');
        this.isAdmin = userService.user.value.roles.includes(Role.ADMIN);
    }

    ngAfterViewInit() {

    }

    onSubmitFilter(filter: RoutingSearch) {
        this.table.filter = filter;
        this.table.update();
    }

}