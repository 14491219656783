import { Title } from "@angular/platform-browser";
import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";
import { UserService } from "../../shared/services/user.service";
import { Role } from "../../shared/models/user.model";
import { NetworksTableComponent } from "../networks-table/networks-table.component";
import { fromEvent, filter, debounceTime, distinctUntilChanged, tap } from "rxjs";

@Component({
    selector: 'app-networks-page',
    templateUrl: 'networks-page.component.html'
})
export class NetworksPageComponent implements AfterViewInit {

    isAdmin = false;

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('countriesModalTpl', { read: TemplateRef, static: false }) countriesModalTpl: any;
    @ViewChild(NetworksTableComponent, { static: false }) table: NetworksTableComponent;

    constructor(private titleService: Title, private modal: ModalService, private userService: UserService) {
        titleService.setTitle('Networks');
        this.isAdmin = userService.user.value.roles.includes(Role.ADMIN);
    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement,'input')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.table.searchValue = this.searchInput.nativeElement.value.trim();
                    this.table.update();
                })
            ).subscribe();
    }

    showCountries() {
        this.modal.alert().component(this.countriesModalTpl).open();
    }
}