import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { Session } from "../../shared/models/session.model";
import { NotificationService } from "../../shared/services/notification.service";
import { UserService } from "../../shared/services/user.service";
import { SessionService } from "../../shared/services/sessions.service";
import { Role } from "../../shared/models/user.model";
import { catchError, throwError } from "rxjs";

type SelectPair = { id: string | number, text: string | number };

@Component({
    selector: 'app-smpp-session-form',
    templateUrl: './smpp-session-form.component.html',
    styleUrls: ['./smpp-session-form.component.scss']
})

export class SmppSessionFormComponent implements OnInit, AfterViewInit {

    @Input() backButtonShow = true;
    @Input() compact = false;

    @Output() onAfterSave = new EventEmitter();

    @ViewChild('confirmWindowEditModalTpl', { read: TemplateRef, static: false }) confirmWindowEditModalTpl: any;
    confirmWindowEditModal: DialogRef;

    isWindowValueEditable: boolean = false;

    @Input() model: Session;

    loading = false;

    isAdmin = false;

    form: FormGroup;

    tonItems: SelectPair[] = [
        { id: 0, text: 0 },
        { id: 1, text: 1 },
        { id: 5, text: 5 },
    ];
    npiItems: SelectPair[] = [
        { id: 0, text: 0 },
        { id: 1, text: 1 },
    ];

    size: number = 20;
    page: number = 1;

    constructor(
        private notificationService: NotificationService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private service: SessionService,
        public validationService: ValidationService,
        private modal: ModalService
    ) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            id: [this.model.id, null],
            systemType: [this.model.systemType, null],
            hostIp: [this.model.hostIp, Vld.compose([Vld.required, Vld.domainOrIp(true), Vld.maxLength(255), Vld.noSpace()])],
            hostPort: [this.model.hostPort, Vld.compose([Vld.required, Vld.digits(true), Vld.noSpace(), Vld.min(0), Vld.max(65535)])],
            systemId: [this.model.systemId, Vld.compose([Vld.required, Vld.maxLength(16), Vld.gsm7(true), Vld.noSpace()])],
            password: ['', Vld.compose([Vld.maxLength(9), Vld.gsm7(true), Vld.noSpace()])],
            enabled: [this.model.enabled, Vld.compose([Vld.required])],
            useSsl: [this.model.useSsl],
            throughput: [this.model.throughput, Vld.compose([Vld.required, Vld.min(1), Vld.max(50)])],
            dstTon: [this.model.dstTon, Vld.compose([Vld.required])],
            dstNpi: [this.model.dstNpi, Vld.compose([Vld.required])],
            windowSize: new FormControl(
                { value: this.model.windowSize, disabled: true },
                Vld.compose([Vld.min(1, false), Vld.max(20, true)])
            ),
            windowWaitTimeout: new FormControl(
                { value: this.model.windowWaitTimeout, disabled: true },
                Vld.compose([Vld.min(0, false), Vld.max(300000, true)])
            ),
            visibleAll: [false]
        });
        this.isAdmin = this.userService.user.value.roles.includes(Role.ADMIN);
        this.isWindowValueEditable = this.isAdmin;
        if (this.isAdmin) {
            this.form.get('windowSize').enable();
            this.form.get('windowWaitTimeout').enable();
        }
    }

    ngAfterViewInit(): void {

    }

    setDefaultValues(session: Session): void {
        session.windowSize = session.windowSize ? session.windowSize : 1;
    }

    isFormValid() {
        return this.form.valid;
    }

    alterFormValuesForPosting(model: Session): void {
        model.hostIp = model.hostIp.trim();
        model.systemType = model.systemType?.length ? model.systemType.trim() : null;
    }

    onWindowEdit(): void {
        this.confirmWindowEditModal = this.modal.alert().component(this.confirmWindowEditModalTpl).dialogClass('modal-dialog small-modal').open();
        this.confirmWindowEditModal.result.then(res => {
            if (res) {
                this.isWindowValueEditable = true;
                this.form.get('windowSize').enable();
                this.form.get('windowWaitTimeout').enable();
            }
        });
    }

    onSubmit() {
        this.loading = true;
        const model: Session = Object.assign({}, this.model, this.form.value)
        this.alterFormValuesForPosting(model);
        this.service.save(model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(() => {
            this.loading = false;
            this.notificationService.success(
                `Session ${this.model.id ? 'updated' : 'created'}`,
                'Products'
            );
            this.onAfterSave.emit(model);
        })
    }
}
