import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SmsPageComponent } from "./sms-page/sms-page.component";
import { SmsTableComponent } from "./sms-table/sms-table.component";
import { SmsTableActionsRendererComponent } from "./sms-table/sms-table-actions-renderer.component";
import { SmsFilterComponent } from "./sms-filter/sms-filter.component";
import { DragDropModule } from "@angular/cdk/drag-drop";


@NgModule({
    declarations: [
        SmsPageComponent,
        SmsTableComponent, SmsTableActionsRendererComponent, SmsFilterComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule, DragDropModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SmsModule {

}