import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProductsTableComponent } from "./products-table/products-table.component";
import { ProductsTableActionsRendererComponent } from "./products-table/products-table-actions-renderer.component";
import { ProductsPageComponent } from "./products-page/products-page.component";
import { SupplierFormComponent } from "./supplier-form/supplier-form.component";
import { SuppliersTableComponent } from "./suppliers-table/suppliers-table.component";
import { SuppliersTableActionsRendererComponent } from "./suppliers-table/suppliers-table-actions-renderer.component";
import { SmppSessionFormComponent } from "./smpp-session-form/smpp-session-form.component";
import { ProductFormComponent } from "./product-form/product-form.component";


@NgModule({
    declarations: [
        ProductsTableComponent, ProductsTableActionsRendererComponent,
        ProductsPageComponent,
        SupplierFormComponent, SuppliersTableComponent, SuppliersTableActionsRendererComponent,
        SmppSessionFormComponent,
        ProductFormComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductsModule {

}