import { Inject, Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class LocalStorage {

    constructor(@Inject('LOCAL_STORAGE_PREFIX') private prefix: string) {
    }

    getItem<T>(key: string, def: T = null): T {
        return LocalStorage.getItem<T>(`${this.prefix}${key}`, def);
    }

    setItem<T>(key: string, value: T) {
        LocalStorage.setItem<T>(`${this.prefix}${key}`, value);
    }

    static getItem<T>(key: string, def: T = null) {
        const raw = window.localStorage.getItem(key);
        if (raw) {
            const data: {value: T} = JSON.parse(raw);
            return data.value;
        }

        return def;
    }

    static setItem<T>(key: string, value: T) {
        const raw = JSON.stringify({value: value});
        window.localStorage.setItem(key, raw);
    }
}