import { Title } from "@angular/platform-browser";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";

@Component({
    selector: 'app-pending-users-page',
    templateUrl: 'pending-users-page.component.html'
})
export class PendingUsersPageComponent {

    @ViewChild('organizationsModalTpl', { read: TemplateRef, static: false }) organizationsModalTpl: any;

    constructor(private titleService: Title, private modal: ModalService) {
        titleService.setTitle('Pending users');
    }

    showOrganizations() {
        this.modal.alert().component(this.organizationsModalTpl).open();
    }
}