import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Session, SessionAllRequestParams } from "../models/session.model";
import { ApiResponse, Page } from "../models/utils";
import { Sms, SmsSearch } from "../models/sms.model";

@Injectable({
    providedIn: 'root'
})
export class SmsService {

    static readonly deliveredStatuses = [
        {id: 'REGISTERED', name: 'REGISTERED'},
        {id: 'ROUTED', name: 'ROUTED'},
        {id: 'SUBMIT_SM_RESP_RECEIVED', name: 'SUBMIT_SM_RESP_RECEIVED'},
        {id: 'DELIVER_SM_RECEIVED', name: 'DELIVER_SM_RECEIVED'},
        {id: 'SENDING_ERROR', name: 'SENDING_ERROR'},
    ];

    constructor( private http: HttpClient) {}

    all(size: number = 20, lastCreatedAt: string = null, search: SmsSearch = null): Observable<Sms[]> {
        const query = {
            size: size,
            lastCreatedAt: lastCreatedAt,
        };
        if (!query.lastCreatedAt) {
            delete query.lastCreatedAt;
        }
        const url = `%BASE_URL%/state-manager/%ROLE%/messages/search`;
        return this.http.post<ApiResponse<Sms[]>>(url, search,{withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

}
