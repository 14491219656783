import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { SmsTableActionFun } from "./sms-table.component";
import { Sms } from "../../shared/models/sms.model";

@Component({
    selector: 'app-sessions-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-text-by-list" tooltip="Edit" (click)="params.onAction('details', params)"></i>
        </div>
    `,
})
export class SmsTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<Sms> & {onAction: SmsTableActionFun};

    agInit(params: ICellRendererParams<Sms> & {onAction: SmsTableActionFun}) {
        this.params = params;
    }

    refresh(params: ICellRendererParams<Sms>): boolean {
        return false;
    }
}