import { Network, NetworkDetails } from "./network.model";


export interface RoutingView {
    id: number
    createdAt: string|Date|null
    routingType: string
    network: NetworkDetails,
    routes: RouteView[]
}

export interface RouteView {
    productId: number
    productName: string
    supplierId: number
    supplierName: string
    routeChannel: string
    price: number
    currency: string

    selected?: boolean
    __filterTextResult?: boolean
    __search?: string
}

export interface RoutingSearch {
    networksIds: number[]
    productsIds: number[]
}

export interface RoutingUpdate {
    id?: number;
    name?: string;
    networkId: number;
    routingType: string;
    routes: RoutingRouteUpdate[];
}

export interface RoutingRouteUpdate {
    id?: number;
    productId: number;
    routeChannel: string;
}

export function routingViewToRoutingUpdate(routing: RoutingView): RoutingUpdate {
    return {
        id: routing.id,
        name: '',
        routingType: routing.routingType,
        networkId: routing.network.id,
        routes: routing.routes.map(r => {
            return {
                id: null,
                productId: r.productId,
                routeChannel: r.routeChannel
            }
        })
    }
}

export class RoutingAllRequestParams {

    size: number = 20;
    page: number = 0;
    networksIds: number[] = [];
    productsIds: number[] = [];

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}