import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { ProductUpdate } from "../../shared/models/product.model";
import { ProductService } from "../../shared/services/product.service";
import { SupplierService } from "../../shared/services/supplier.service";
import { SessionService } from "../../shared/services/sessions.service";
import { Supplier, SupplierAllRequestParams } from "../../shared/models/supplier.model";
import { Session, SessionAllRequestParams } from "../../shared/models/session.model";
import { DialogRef, ModalService } from "../../shared/services/modal.service";

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

    @Input() model: ProductUpdate;
    @Output() onAfterSave = new EventEmitter<ProductUpdate>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    suppliersLoading = false;
    suppliers: Supplier[] = [];
    @ViewChild('supFormModalTpl', { read: TemplateRef, static: false }) supFormModalTpl: any;
    supplierFormModal: DialogRef;

    sessionsLoading = false;
    sessions: Session[] = [];
    @ViewChild('sessionModalTpl', { read: TemplateRef, static: false }) sessionModalTpl: any;
    sessionModal: DialogRef;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private productService: ProductService,
        public supplierService: SupplierService,
        public sessionService: SessionService,
        private notifications: NotificationService,
        private modalService: ModalService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, V.compose([V.required, V.maxLength(250)])],
            enabled: [this.model.enabled],
            supplierId: [this.model.supplierId, V.compose([V.required])],
            channelType: [this.model.channelType, V.compose([V.required])],
            channelId: [this.model.channelId, V.compose([V.required])],
        });
        if (this.model.id) {
            this.form.controls.supplierId.disable();
        }
        this.updateSessions();
        this.updateSuppliers();
    }

    updateSuppliers() {
        let params = new SupplierAllRequestParams();
        params.page = 0;
        params.size = 100;
        this.suppliersLoading = true;
        if (!this.model.id) {
            this.form.controls.supplierId.disable();
        }
        this.supplierService.all(params).subscribe(d => {
            this.suppliers = d.content;
            this.suppliersLoading = false;
            if (!this.model.id) {
                this.form.controls.supplierId.enable();
            }
        });
    }

    updateSessions() {
        let params = new SessionAllRequestParams();
        params.page = 0;
        params.size = 100;
        this.sessionsLoading = true;
        this.form.controls.channelId.disable();
        this.sessionService.all(params).subscribe(d => {
            this.sessions = d.content;
            this.sessionsLoading = false;
            this.form.controls.channelId.enable();
        });
    }

    onClickCreateSupplier() {
        this.supplierFormModal = this.modalService.alert().isBlocking(true).component(this.supFormModalTpl).open();
    }

    onClickCreateSession() {
        this.sessionModal = this.modalService.alert().isBlocking(true).component(this.sessionModalTpl).open();
    }

    onAfterSaveSupplier() {
        this.supplierFormModal.close();
        this.updateSuppliers();
        this.form.controls.supplierId.setValue(null)
    }

    onAfterSaveSession() {
        this.sessionModal.close();
        this.updateSessions();
        this.form.controls.channelId.setValue(null)
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as ProductUpdate;
        this.productService.save(value).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Product ' + (this.model.id ? 'updated' : 'created'), 'Products');
            this.onAfterSave.emit(value);
        });
    }

}
