import { LocalStorage } from "../services/local-storage.service";

export interface Filter {
    filterKey: string,
    title: string,
    show: boolean,
}

export interface IFilterDef {
    title: string,
    value?: string,
    show: boolean,
    order?: number
}

export interface IFilterState {
    title: string,
    show: boolean,
    order?: number
}

export function saveFilters(storage: LocalStorage, key: string, filters: IFilterDef[]) {
    storage.setItem<IFilterState[]>(key, filters.map((_, i) => {
        return {title: _.title, show: _.show, order: _.order ? _.order : (i + 1)};
    }));
}


export function loadFilters(storage: LocalStorage, key: string, filters: IFilterDef[]) {
    const saved = storage.getItem<IFilterState[]>(key);
    if (saved) {
        saved.forEach(state => {
           filters.filter(_ => _.title === state.title).forEach(f => {
               f.show = state.show;
               if (state.order) {
                   f.order = state.order;
               }
           });
        });
    }
}

export function sortFilters(filters: IFilterDef[]): IFilterDef[] {
    return filters.sort((a, b) => a.order - b.order).sort((a, b) => Number(b.show) - Number(a.show));
}