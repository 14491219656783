import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { NetworksTableActionFun } from "./networks-table.component";
import { Network } from "../../shared/models/network.model";
import { Role } from "../../shared/models/user.model";
import { UserService } from "../../shared/services/user.service";

@Component({
    selector: 'app-networks-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-text-by-list" tooltip="Prefixes" (click)="params.onAction('prefixes', params)"></i>
            <i *ngIf="isAdmin" class="cp icon-edit" tooltip="Edit" (click)="params.onAction('edit', params)"></i>
            <i *ngIf="isAdmin" class="cp icon-delete" tooltip="Delete" (click)="params.onAction('delete', params)"></i>
        </div>
    `,
})
export class NetworksTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<Network> & {onAction: NetworksTableActionFun};
    isAdmin = false;

    constructor(private userService: UserService) {
    }

    agInit(params: ICellRendererParams<Network> & {onAction: NetworksTableActionFun}) {
        this.isAdmin = this.userService.user.value.roles.includes(Role.ADMIN);
        this.params = params;
    }

    refresh(params: ICellRendererParams<Network>): boolean {
        return false;
    }
}