import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { PlatformUser } from "../../shared/models/user.model";
import { PendingUsersTableActionFun } from "./pending-users-table.component";

@Component({
    selector: 'app-pending-users-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-check" tooltip="Approve" (click)="params.onAction('approve', params)"></i>
            <i class="cp icon-close" tooltip="Decline" (click)="params.onAction('decline', params)"></i>
        </div>
    `,
})
export class PendingUsersTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<PlatformUser> & {onAction: PendingUsersTableActionFun};

    agInit(params: ICellRendererParams<PlatformUser> & {onAction: PendingUsersTableActionFun}) {
        this.params = params;
    }

    refresh(params: ICellRendererParams<PlatformUser>): boolean {
        return false;
    }
}