
import { Component, Inject } from '@angular/core';
import { UserService } from "../../shared/services/user.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {

    constructor(private userService: UserService) {
    }

    ngOnInit() {
        this.userService.toLogin();
    }
}
