import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { Supplier } from "../../shared/models/supplier.model";
import { SupplierService } from "../../shared/services/supplier.service";

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html'
})
export class SupplierFormComponent implements OnInit {

    @Input() model: Supplier;
    @Output() onAfterSave = new EventEmitter<Supplier>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private supplierService: SupplierService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, V.compose([V.required, V.maxLength(250)])],
        });
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as Supplier;
        this.supplierService.save(value).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Supplier ' + (this.model.id ? 'updated' : 'created'), 'Products');
            this.onAfterSave.emit(value);
        });
    }

}
