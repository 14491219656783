export interface Session {
    id?: number;
    userId?: string;
    dstNpi?: number;
    dstTon?: number;
    enabled?: boolean;
    lastError?: string;
    online?: boolean;
    hostIp?: string;
    hostPort?: number;
    password?: string;
    systemId?: string;
    systemType?: string;
    serviceType?: string;
    throughput?: number;
    supplierCount?: number;
    concatenationType?: string;
    windowSize?: number;
    windowWaitTimeout?: number;
    useSsl?: boolean
}

export class SessionAllRequestParams {

    size: number = 20;
    page: number = 0;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}