import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { RoutingTableActionFun } from "./routing-table.component";
import { Product } from "../../shared/models/product.model";
import { Role } from "../../shared/models/user.model";
import { UserService } from "../../shared/services/user.service";
import { RoutingView } from "../../shared/models/routing.model";

@Component({
    selector: 'app-products-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-edit" tooltip="Edit" (click)="params.onAction('edit', params)"></i>
            <i class="cp icon-delete" tooltip="Delete" (click)="params.onAction('delete', params)"></i>
        </div>
    `,
})
export class RoutingTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<RoutingView> & {onAction: RoutingTableActionFun};
    isAdmin = false;

    constructor(private userService: UserService) {
    }

    agInit(params: ICellRendererParams<RoutingView> & {onAction: RoutingTableActionFun}) {
        this.isAdmin = this.userService.user.value.roles.includes(Role.ADMIN);
        this.params = params;
    }

    refresh(params: ICellRendererParams<Product>): boolean {
        return false;
    }
}