
import { Component, EventEmitter, Input, Output, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
declare var moment: any;
declare var $: any;

@Component({
    selector: 'input-date-time',
    templateUrl: './input.dateTime.html',
    styleUrls: ['input.search.datePeriod.scss'],
})

export class InputDateTime implements OnInit {

    @Input() placeholder = 'Date';
    @Input() validationService;
    @Input() validator;
    @Input() value;
    @Input() timePicker = true;
    @Input() clear = false;
    @Input() minDate;
    @Output() change: EventEmitter<any> = new EventEmitter();
    model: string = '';
    control: FormControl;
    format: string = 'MM/DD/YYYY HH:mm';
    $input;

    constructor(public elementRef: ElementRef, private notificationService: NotificationService) { }

    ngOnInit() {
        if (!this.timePicker) {
            this.format = 'MM/DD/YYYY';
        }
        this.control = new FormControl();
        if (this.validator) {
            this.control.setValidators(this.validator);
        }
        let $root = $(this.elementRef.nativeElement);
        this.$input = $root.find('.form-control');
        let value, old;
        this.$input.daterangepicker({
            format: this.format,
            singleDatePicker: true,
            showDropdowns: true,
            showWeekNumbers: false,
            timePicker: this.timePicker,
            minDate: this.minDate ? this.minDate : null,
            timePickerIncrement: 1,
            timePicker24Hour: true,
            timePicker12Hour: false,
            timePickerSeconds: false,
            autoApply: this.timePicker === false,
            autoUpdateInput: this.clear,
            opens: 'right',
            drops: 'down',
            buttonClasses: ['btn', 'btn-sm'],
            applyClass: 'btn-primary',
            cancelClass: 'btn-default',
            ranges: false,
            locale: {
                applyLabel: 'Submit',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 1
            }
        }, (start, end, label) => {
            value = start.unix();
        }).on('show.daterangepicker', (ev, picker) => {
            if (value === undefined && !this.value) {
                value = moment().add(1, 'minutes').unix();
                this.notificationService.info('The selected date is scheduled after a minute. Please change this if needed.', 'Scheduler');
            }
        }).on('apply.daterangepicker', (ev, picker) => {
            if (value === undefined && this.timePicker === false) {
                value = moment().add(1, 'minutes').unix();
                this.notificationService.info('The selected date is scheduled after a minute. Please change this if needed.', 'Scheduler');
            }
            if (value !== old) {
                this.change.emit(value);
                this.control.patchValue(value);
                this.model = moment.unix(value).format(this.format);
                old = value;
            }
        });
        if (this.value) {
            this.updateValue();
            if (this.model) {
                this.$input.data('daterangepicker').setStartDate(this.model);
                this.$input.data('daterangepicker').setEndDate(this.model);
            }
        }
    }

    updateValue() {
        this.model = moment.unix(typeof this.value === 'number' ? this.value : (Date.parse(this.value) / 1000)).format(this.format);
        this.control.patchValue(this.model);
    }

    onClickClear() {
        this.$input.val('');
        this.control.patchValue('');
        this.model = '';
        this.change.emit(null);
    }
}
