import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { Organization, PlatformUser } from "../../shared/models/user.model";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { OrganizationsTableActionsRendererComponent } from "./organizations-table-actions-renderer.component";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
    selector: 'app-organizations-table',
    templateUrl: './organizations-table.component.html',
    styleUrls: [],
})
export class OrganizationsTableComponent implements OnInit, OnDestroy {

    theme = AgGridUtils.theme;
    gridApi: GridApi<Organization>;

    gridOptions: GridOptions<Organization> = {
        onGridReady: e => {
            this.gridApi = e.api;
            this.update();
        },
        columnDefs: [
            {
                headerName: 'Name',
                valueGetter: (params) => params.data.name,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                resizable: false,
                getQuickFilterText: params => params.data.name
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: OrganizationsTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: OrganizationsTableAction, params: ICellRendererParams<Organization>) => this.onAction(action, params),
                },
                getQuickFilterText: params => '',
            }
        ],
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    };

    rows: Organization[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('orgFormModalTpl', { read: TemplateRef, static: false }) orgFormModalTpl: any;
    orgModel: Organization;
    orgFormModal: DialogRef;

    @Input() selectMode = false;

    constructor(
        private userService: UserService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit() {

    }

    update() {
        this.loading = true;
        this.$update = this.userService.allOrganizations()
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                this.rows = rows;
                this.loading = false;
            });
    }

    private onAction(action: OrganizationsTableAction, params: ICellRendererParams<Organization>) {
        if (action === 'edit') {
            this.orgModel = Object.assign({}, params.data);
            this.orgFormModal = this.modalService.alert().isBlocking(true).component(this.orgFormModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.userService.deleteOrganization(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Organization deleted', 'Users');
                    this.update();
                });
            });
        }
    }

    onAfterSave() {
        this.orgFormModal.close();
        this.update();
    }

    onCreateOrg() {
        this.orgFormModal = this.modalService.alert().isBlocking(true).component(this.orgFormModalTpl).open();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type OrganizationsTableAction = 'edit'|'delete';
export type OrganizationsTableActionFun = (action: OrganizationsTableAction, params: ICellRendererParams<Organization>) => void;