import {
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    HostListener,
    ViewContainerRef,
    TemplateRef,
    EmbeddedViewRef, Output, EventEmitter
} from '@angular/core';
import { createPopper, Instance } from '@popperjs/core';

export type Placement = "auto" | "auto-start" | "auto-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" | "top" | "left" | "bottom" | "right";

@Directive({
    selector: '[dropdown]'
})
export class DropdownDirective implements OnDestroy {

    @Input() dropdown: TemplateRef<any>|HTMLElement;

    @Input() dropdownHideAfterClick = false;

    @Input() dropdownPlacement: Placement = 'bottom';

    @Input() dropdownDestroyAfterHide = false;

    @Output() onDropdownShow = new EventEmitter<DropdownCtx>();

    @Output() onDropdownHide = new EventEmitter<DropdownCtx>();

    private view: EmbeddedViewRef<any>;

    private content: HTMLElement;

    private popper: Instance;

    private isShow = false;

    private ctx: DropdownCtx = {
        hide: () => {this.hide();},
        show: () => {this.show();},
        destroy: () => {
            if (this.isShow) { this.hide(); }
            this.destroy();
        }
    };

    constructor(private el: ElementRef, private viewContainer: ViewContainerRef ) {}

    @HostListener('click') click() {
        this.show();
    }

    private show() {
        if (this.isShow) {return;}
        this.isShow = true;
        if (!this.view ||!this.content) {
            this.render();
        } else {
            this.popper.update();
        }
        this.el.nativeElement.classList.add('dropdown-open')
        this.content.classList.add('show');
        this.onDropdownShow.emit(this.ctx);
        setTimeout(() => this.setupEvents());
    }

    private hide() {
        if (!this.isShow) {return;}
        this.isShow = false;
        this.el.nativeElement.classList.remove('dropdown-open')
        this.content.classList.remove('show');
        this.onDropdownHide.emit(this.ctx);
        if (this.dropdownDestroyAfterHide) {
            this.destroy();
        }
    }

    private render() {
        if (this.dropdown instanceof HTMLElement) {
            this.content = this.dropdown;
        } else {
            this.view = this.viewContainer.createEmbeddedView(this.dropdown, {dropdown: this.ctx});
            this.content = this.view.rootNodes[0] as HTMLElement;
        }

        this.popper = createPopper(this.el.nativeElement, this.content, {
            placement: this.dropdownPlacement,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    }

    private destroy() {
        if (this.view) {
            this.view.destroy();
            this.view = null;
        }
        if (this.popper) {
            this.popper.destroy();
        }
        this.content = null;
    }

    private setupEvents() {
        document.addEventListener('click', (e: MouseEvent) => {
            if ((!this.view && !this.content) || !this.isShow) {return;}
            if (!e.composedPath().includes(this.content)) {
                e.stopPropagation();
                this.hide();
                return;
            } else if (this.dropdownHideAfterClick) {
                this.hide();
            }
            this.setupEvents();
        }, {once: true});
    }

    ngOnDestroy(): void {
        this.destroy();
    }
}

export interface DropdownCtx {
    show(): void
    hide(): void
    destroy(): void
}
