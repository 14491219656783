import { Pipe } from '@angular/core';

@Pipe({name: 'sort'})

export class SortPipe  {
    transform<T>(array: T[], args: string): T[] {
        if (!args[0]) {
            return array;
        }
        let direction = args[0][0];
        let column = args.replace('-','');
        array.sort((a: any, b: any) => {
            let left = Number(new Date(a[column]));
            let right = Number(new Date(b[column]));
            return (direction === "-") ? right - left : left - right;
        });
        return array;
    }
}