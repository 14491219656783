import { Title } from "@angular/platform-browser";
import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";
import { UserService } from "../../shared/services/user.service";
import { Role } from "../../shared/models/user.model";
import { fromEvent, filter, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { SessionsTableComponent } from "../sessions-table/sessions-table.component";

@Component({
    selector: 'app-sessions-page',
    templateUrl: 'sessions-page.component.html'
})
export class SessionsPageComponent implements AfterViewInit {

    isAdmin = false;

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild(SessionsTableComponent, { static: false }) table: SessionsTableComponent;

    constructor(private titleService: Title, private modal: ModalService, private userService: UserService) {
        titleService.setTitle('Sessions');
        this.isAdmin = userService.user.value.roles.includes(Role.ADMIN);
    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement,'input')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.table.searchValue = this.searchInput.nativeElement.value.trim();
                    this.table.update();
                })
            ).subscribe();
    }
}