import { Session } from "./session.model";

export interface Product {
    id?: number;
    name?: string;
    channelType: string;
    channelId?: number;
    enabled?: boolean;
    supplierId?: number;
    supplierName?: string;
    createdAt?: string;
    session?: Session;
}

export interface ProductUpdate {
    id?: number;
    name?: string;
    channelType: string;
    channelId?: number;
    enabled?: boolean;
    supplierId?: number;
}

export function productToProductUpdate(p: Product): ProductUpdate {
    return {
        id: p.id,
        name: p.name,
        channelType: p.channelType,
        channelId: p.channelId,
        enabled: p.enabled,
        supplierId: p.supplierId
    }
}

export class ProductAllRequestParams {

    size: number = 20;
    page: number = 0;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}