import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { Country } from "../../shared/models/network.model";
import { NetworkService } from "../../shared/services/network.service";

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html'
})
export class CountryFormComponent implements OnInit {

    @Input() model: Country;
    @Output() onAfterSave = new EventEmitter<Country>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private networkService: NetworkService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, V.compose([V.required, V.maxLength(250)])],
            isoAlpha2: [this.model.isoAlpha2, V.compose([V.maxLength(2)])],
            isoAlpha3: [this.model.isoAlpha3, V.compose([V.maxLength(3)])],
            countryCallingCode: [this.model.countryCallingCode, V.compose([V.maxLength(15)])],
            mnpSupported: [this.model.mnpSupported],
        });
        if (!this.model.id) {
            this.form.controls.countryCallingCode.addValidators(V.required);
            this.form.controls.mnpSupported.addValidators(V.required);
        }
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as Country;
        this.networkService.saveCountry(value).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Country ' + (this.model.id ? 'updated' : 'created'), 'Networks');
            this.onAfterSave.emit(org);
        });
    }

}
