import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import { UserService } from "./user.service";
import { Router } from "@angular/router";


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private notificationService: NotificationService,
        private userService: UserService,
        private router: Router
    ) {}

    handleUpdates(request: HttpRequest<any>, response: HttpResponse<any>): void {
        if (!environment['forcedUpdate']) {
            if (['DELETE', 'POST', 'PUT'].includes(request.method) && response.status === 200) {
                setTimeout(() => {
                    this.notificationService.setUpdates(response);
                }, 1000);
            }
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone();
        return next.handle(newReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const newEvent = event.clone();
                    this.handleUpdates(newReq, newEvent);
                    return newEvent;
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.userService.isAuth.next(false);
                    this.router.navigate(['/login']).then();
                } else {
                    this.notificationService.handleHttpError(error);
                }
                return throwError(() => error);
            })
        );
    }
}