import { CDK_DRAG_CONFIG } from "@angular/cdk/drag-drop";
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE,
    NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app/app/app.component";
import { routes } from "./app/routes";
import { environment } from "./environments/environment";
import { LoginComponent } from "./app/auth/login/login.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./app/shared/services/http-error-interceptor.service";
import { UserService } from "./app/shared/services/user.service";
import { LicenseManager } from "ag-grid-enterprise";
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from "ng-recaptcha";
import { HttpUrlInterceptor } from "./app/shared/services/http-url.interceptor";
import { UsersModule } from "./app/users/users.module";
import { SharedModule } from "./app/shared/shared.module";
import { NetworksModule } from "./app/networks/networks.module";
import { SessionsModule } from "./app/sessions/sessions.module";
import { SmsModule } from "./app/sms/sms.module";
import { ApiModule } from "./app/api/api.module";
import { ProductsModule } from "./app/products/products.module";
import { RoutingModule } from "./app/routing/routing.module";

export function initApp(userService: UserService) {
  return () => {
    return new Promise((resolve, reject) => {
      userService.init().then(() => {
        resolve(true);
      });
    });
  };
}

if (environment.agGridLicenseKey) {
  LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
    SharedModule,
    UsersModule,
    NetworksModule,
    SessionsModule,
    ProductsModule,
    RoutingModule,
    SmsModule,
    ApiModule,
  ],
  providers: [
    { provide: "API_URL", useValue: environment.apiUrl },
    { provide: "FRONTEND_URL", useValue: environment.frontendUrl },
    { provide: "KEYCLOAK_URL", useValue: environment.keycloakUrl },
    { provide: "LOCAL_STORAGE_PREFIX", useValue: environment.storagePrefix },
    { provide: "STATUSPAGE_ID", useValue: environment.statusPageId },
    { provide: "RELEASE_VERSION", useValue: environment.releaseVersion },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    { provide: RECAPTCHA_BASE_URL, useValue: environment.recaptchaBaseUrl }, // because google does not work in China ...
    { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [UserService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule {}
