
import {Component, Attribute, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'progressbar',
    templateUrl: 'progressbar.component.html',
})

export class Progressbar implements OnChanges {

    @Attribute('class') className;

    @Input() max = 0;
    @Input() value = 0;

    percent = 0;

    calc() {
        this.percent = (this.value / this.max) * 100;
    }

    ngOnChanges(changes) {
        if (changes.max || changes.value) {
            this.calc();
        }
    }
}