import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-slide-button',
    templateUrl: './slide-button.component.html',
    styleUrls: ['./slide-button.component.scss']
})
export class SlideButtonComponent implements OnInit {

    @Output() onConfirmSwipe = new EventEmitter<boolean>();

    slideButtonItems = [{
        text: '>>',
        class: 'start',
    }, {
        text: 'Slide to confirm',
        class: 'intermediate',
    }, {
        text: ' ',
        class: 'end'
    }];

    constructor() {
    }

    ngOnInit(): void {

    }

    drop(event: CdkDragDrop<unknown>) {
        if (event.currentIndex === this.slideButtonItems.length - 1) {
            moveItemInArray(this.slideButtonItems, event.previousIndex, event.currentIndex);
            this.onConfirmSwipe.emit(true);
        }
    }

}
