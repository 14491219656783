import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Session, SessionAllRequestParams } from "../models/session.model";
import { ApiResponse, Page } from "../models/utils";

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor( private http: HttpClient) {}

    all(params: SessionAllRequestParams): Observable<Page<Session>> {
        const query = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/routing/%ROLE%/sessions`;
        return this.http.get<ApiResponse<Page<Session>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    one(id: number): Observable<Session> {
        const url = `%BASE_URL%/routing/%ROLE%/sessions/${id}`;
        return this.http.get<ApiResponse<Session>>(url, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    toggleEnabled(ids: number[], enabled: boolean) {
        const url = `%BASE_URL%/routing/%ROLE%/sessions/toggle-enabled`;
        return this.http.post<void>(url, null,{
            withCredentials: true,
            params: {
                ids: ids.join(','),
                enabled: enabled ? 'true' : 'false'
            }
        });
    }

    save(model: Session): Observable<void> {
        if (model.password) {
            model.password = String(model.password).trim();
        }
        if (model.id) {
            const url = `%BASE_URL%/routing/%ROLE%/sessions/${model.id}`;
            return this.http.put<void>(url, model,{withCredentials: true});
        }
        const url = `%BASE_URL%/routing/%ROLE%/sessions`;
        return this.http.post<void>(url, model,{withCredentials: true});
    }

    delete(id: number): Observable<void> {
        const url = `%BASE_URL%/routing/%ROLE%/sessions/${id}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): Session {
        return {
            id: null,
            dstNpi: 1,
            dstTon: 1,
            enabled: true,
            useSsl: false,
            concatenationType: 'udh',
            systemType: null,
            serviceType: null,
            hostIp: '',
            hostPort: null,
            password: null,
            systemId: null,
            throughput: 5,
            windowSize: 1,
            windowWaitTimeout: 60000
        };
    }

}
