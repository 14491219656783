import { Component, OnInit, ViewChild } from '@angular/core';
import { SmsSearch } from "../../shared/models/sms.model";
import { SmsTableComponent } from "../sms-table/sms-table.component";

@Component({
    selector: 'app-sms-page',
    templateUrl: './sms-page.component.html',
    styleUrls: ['./sms-page.component.scss']
})
export class SmsPageComponent implements OnInit {

    @ViewChild(SmsTableComponent, { static: false }) table: SmsTableComponent;

    constructor() {
    }

    ngOnInit() {
    }

    onSearch(search: SmsSearch) {
        this.table.search = search;
        this.table.update(true);
    }

}