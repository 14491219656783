import { Pipe, PipeTransform } from '@angular/core';


/*
   Usage:
   const longStr = 'A really long string that needs to be truncated.';
   
   <h1>{{longStr | truncate }}</h1> 
   <!-- Outputs: A really long string that... -->

   <h1>{{longStr | truncate : 12 }}</h1> 
   <!-- Outputs: A really lon... -->

   <h1>{{longStr | truncate : 12 : true }}</h1> 
   <!-- Outputs: A really... -->

   <h1>{{longStr | truncate : 12 : false : '***' }}</h1> 
   <!-- Outputs: A really lon*** -->

*/
@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {


    transform(value: string, limit: number = 25, completeWords: boolean = false, ellipsis: string = '...'): string {
        if (completeWords) {
            limit = value.substring(0, limit).lastIndexOf(' ');
        }
        return value.length > limit ? value.substring(0, limit) + ellipsis : value;
    }
}