import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Session, SessionAllRequestParams } from "../models/session.model";
import { ApiResponse, Page } from "../models/utils";
import { Sms, SmsSearch } from "../models/sms.model";
import { ApiKey } from "../models/api.model";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) {}

    getMyApiKey(): Observable<ApiKey> {
        const url = `%BASE_URL%/state-manager/%ROLE%/apikey`;
        return this.http.get<ApiResponse<ApiKey>|null>(url, {withCredentials: true}).pipe(
            map(r => {
                if (!r) { return null; }
                return this.prepareApiKey(r.data);
            })
        );
    }

    createMyApiKey(): Observable<ApiKey> {
        const url = `%BASE_URL%/state-manager/%ROLE%/apikey`;
        return this.http.post<ApiResponse<ApiKey>>(url, null, {withCredentials: true}).pipe(
            map(r => this.prepareApiKey(r.data))
        );
    }

    deleteMyApiKey(): Observable<void> {
        const url = `%BASE_URL%/state-manager/%ROLE%/apikey`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    createMySecondaryApiKey(): Observable<ApiKey> {
        const url = `%BASE_URL%/state-manager/%ROLE%/apikey/secondary`;
        return this.http.post<ApiResponse<ApiKey>>(url, null, {withCredentials: true}).pipe(
            map(r => this.prepareApiKey(r.data))
        );
    }

    rotateMySecondaryApiKey(): Observable<void> {
        const url = `%BASE_URL%/state-manager/%ROLE%/apikey/rotate`;
        return this.http.post<void>(url, null, {withCredentials: true});
    }

    private prepareApiKey(k: ApiKey): ApiKey {
        if (k.createdAt) {
            k.createdAt = new Date(k.createdAt);
        }
        if (k.updatedAt) {
            k.updatedAt = new Date(k.updatedAt);
        }
        if (k.emailNotifiedAt) {
            k.emailNotifiedAt = new Date(k.emailNotifiedAt);
        }
        return k;
    }

}
