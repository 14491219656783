import { Subject } from 'rxjs';
import { Inject, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { httpStatuses } from "../models/http-statuses";
import { BrowserUtils } from "./browser-utils";

@Injectable()
export class NotificationService {

    private services = ['number-intelligence', 'state-manager', 'billing', 'api', 'sessions-crud'];

    notifications: Subject<Notification> = new Subject();
    updates: Subject<any> = new Subject();

    constructor(@Inject('API_URL') private baseUrl: string) {
    }

    setUpdates(data: any): void {
        this.updates.next(data);
    }

    success(message: string, title = ''): void {
        this.notifications.next({
            type: 'success',
            title: title,
            content: message
        });
    }

    info(message: string, title = ''): void {
        this.notifications.next({
            type: 'info',
            title: title,
            content: message
        });
    }

    warning(message: string, title = '') {
        this.notifications.next({
            type: 'warning',
            title: title,
            content: message
        });
    }

    error(message: any, title = '') {
        this.notifications.next({
            type: 'error',
            title: title,
            content: message
        });
    }

    handleHttpError(response: HttpErrorResponse) {
        if (response.status === 0 && !BrowserUtils.isUnload) {
            this.info('Connection issue encountered. Check your internet connection', 'Network');
        }
        let statusText = response.statusText;
        if (statusText === 'OK' && !response.ok) {
            statusText = 'Error ' + response.status;
            if (httpStatuses[String(response.status)]) {
                statusText = httpStatuses[String(response.status)];
            }
        }
        let resultMessage = '';
        resultMessage += '<strong>Response status</strong>: ' + response.status + '<br>';
        resultMessage += '<strong>Service</strong>: ' + this.extractServiceFormUrl(response.url) + '<br>';
        if (response.error && response.error.errors && response.error.errors.length) {
            let errorText = response.error.errors.join('<br>');
            resultMessage += '<strong>Response message</strong>: <br>' + errorText;
        }
        this.error(resultMessage, statusText);
    }

    private extractServiceFormUrl(url: string): string {
        for (let s of this.services) {
            if (url.includes(`/${s}`)) {return s;}
        }
        if (url.includes(this.baseUrl)) {
            return 'gateway';
        }
        return 'unknown';
    }
}

export interface Notification {
    title: string,
    content: string,
    ttl?: number,
    type?: string
}