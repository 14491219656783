import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    /**
     * @see http://numeraljs.com/#format
     */
    transform(value: number, format: string = '0,0'): string {
        return numeral(value).format(format);
    }
}