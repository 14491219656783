import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ApikeyClientPageComponent } from "./apikey-client-page/apikey-client-page.component";


@NgModule({
    declarations: [
        ApikeyClientPageComponent,
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule, DragDropModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApiModule {

}