
export class AgGridUtils {
    public static readonly theme = 'ag-theme-alpine';

    public static getWrapperMenu(): HTMLElement {
        let wrappers = document.body.getElementsByClassName('ag-popup'), wrapper;
        if (wrappers.length) {
            wrapper = wrappers.item(0);
        } else  {
            wrapper = AgGridUtils.el('div', ['ag-popup', AgGridUtils.theme]);
            document.body.append(wrapper);
        }
        return wrapper;
    }

    public static el(tagName: string, classList: string[]): HTMLElement {
        const el = document.createElement(tagName);
        el.classList.add(...classList);
        return el;
    }
}