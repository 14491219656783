import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { PrefixesTableActionFun } from "./prefixes-table.component";
import { Country, Prefix } from "../../shared/models/network.model";

@Component({
    selector: 'app-prefixes-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-edit" tooltip="Edit" (click)="params.onAction('edit', params)"></i>
            <i class="cp icon-delete" tooltip="Delete" (click)="params.onAction('delete', params)"></i>
        </div>
    `,
})
export class PrefixesTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<Prefix> & {onAction: PrefixesTableActionFun};

    agInit(params: ICellRendererParams<Prefix> & {onAction: PrefixesTableActionFun}) {
        this.params = params;
    }

    refresh(params: ICellRendererParams<Prefix>): boolean {
        return false;
    }
}