import { Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';

@Component({
    selector: 'app-ag-tooltip',
    templateUrl: './ag-tooltip.component.html',
    styleUrls: ['./ag-tooltip.component.scss']
})
export class AgTooltipComponent {

    constructor() { }

    private params!: ITooltipParams;
    private currentHoverFieldId: string;
    tooltipData: string = '';

    agInit(params: ITooltipParams): void {
        this.params = params;
        if (params.location === 'header') {
            this.tooltipData = params.value;
            return;
        }
        const currentRow = params.data;
        this.currentHoverFieldId = params.column?.getUniqueId();
        switch (this.currentHoverFieldId) {
            case 'senderDelivered':
                if (currentRow.senderDelivered !== currentRow.senderSent) {
                    this.tooltipData = currentRow.senderSent;
                }
                break;
            case 'user':
                this.tooltipData = currentRow.user.username;
                break;
            case 'supplierCount':
                this.tooltipData = currentRow.supplierCount ? 'Show all' : null;
                break;
            default:
                this.tooltipData = null;
        }
    }
}
