import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from "../../shared/services/api.service";
import { NotificationService } from "../../shared/services/notification.service";
import { Title } from "@angular/platform-browser";
import { ModalService } from "../../shared/services/modal.service";
import { ApiKey } from "../../shared/models/api.model";
import { catchError, throwError } from "rxjs";

@Component({
    selector: 'app-apikey-client-page',
    templateUrl: './apikey-client-page.component.html',
    styleUrls: ['./apikey-client-page.component.scss']
})
export class ApikeyClientPageComponent implements OnInit {

    loading = false;
    myApiKey: ApiKey;
    copyKey = '';

    @ViewChild('copyKeyModalTpl', { read: TemplateRef, static: false }) copyKeyModalTpl: any;

    constructor(
        titleService: Title,
        private modal: ModalService,
        private apiService: ApiService,
        private notifications: NotificationService
    ) {
        titleService.setTitle('API');
    }

    ngOnInit() {
        this.loading = true;
        this.apiService.getMyApiKey().pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(k => {
            this.loading = false;
            this.myApiKey = k;
        });
    }

    onClickActivate() {
        this.loading = true;
        this.apiService.createMyApiKey().pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(k => {
            this.loading = false;
            this.notifications.success('API activated', 'API settings')
            this.myApiKey = k;
            this.showCopyKeyModal(k.accessKey);
        });
    }

    onClickDeactivate() {
        this.modal.confirm().then(confirm => {
            if (!confirm) {return;}
            this.loading = true;
            this.apiService.deleteMyApiKey().pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            ).subscribe(k => {
                this.loading = false;
                this.notifications.success('API deactivated', 'API settings')
                this.myApiKey = null;
            });
        });
    }

    onClickSecondaryGenerate() {
        this.apiService.createMySecondaryApiKey().pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(k => {
            this.loading = false;
            this.notifications.success('Secondary key generated', 'API settings')
            this.myApiKey = k;
            this.showCopyKeyModal(k.secondaryAccessKey);
        });
    }

    onClickMakePrimary() {
        this.modal.confirm().then(confirm => {
            if (!confirm) {
                return;
            }
            this.apiService.rotateMySecondaryApiKey().pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            ).subscribe(() => {
                this.loading = false;
                this.notifications.success('Secondary key make primary', 'API settings')
                this.myApiKey.hasSecondaryAccessKey = false;
            });
        });

    }

    showCopyKeyModal(key: string) {
        this.copyKey = key;
        this.modal.alert().isBlocking(true).component(this.copyKeyModalTpl).open();
    }

    async onClickCopy(textToCopy: string): Promise<void> {
        try {
            await navigator.clipboard.writeText(textToCopy);
            this.notifications.success('Copied to clipboard', 'API settings');
        } catch (err) {
            this.notifications.error('Fail to copy to clipboard. Please copy it manually.', 'API settings');
        }
    }

}