import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Session, SessionAllRequestParams } from "../models/session.model";
import { ApiResponse, Page } from "../models/utils";
import { Product, ProductAllRequestParams, ProductUpdate } from "../models/product.model";

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor( private http: HttpClient) {}

    all(params: ProductAllRequestParams): Observable<Page<Product>> {
        const query = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/routing/%ROLE%/products`;
        return this.http.get<ApiResponse<Page<Product>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: ProductUpdate): Observable<void> {
        if (model.id) {
            const url = `%BASE_URL%/routing/%ROLE%/products/${model.id}`;
            return this.http.put<void>(url, model,{withCredentials: true});
        }
        const url = `%BASE_URL%/routing/%ROLE%/products`;
        return this.http.post<void>(url, model,{withCredentials: true});
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/routing/%ROLE%/products?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): ProductUpdate {
        return {
            id: null,
            name: '',
            enabled: true,
            channelType: 'SMPP',
            channelId: null,
            supplierId: null,
        };
    }

}
