import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RoutingPageComponent } from "./routing-page/routing-page.component";
import { RoutingFilterComponent } from "./routing-filter/routing-filter.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { RoutesSelectComponent } from "./routes-select/routes-select.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { RoutingTableComponent } from "./routing-table/routing-table.component";
import { RoutingTableActionsRendererComponent } from "./routing-table/routing-table-actions-renderer.component";
import { RoutingFormComponent } from "./routing-form/routing-form.component";

@NgModule({
    declarations: [
        RoutingPageComponent, RoutingFilterComponent, RoutesSelectComponent, RoutingTableComponent, RoutingTableActionsRendererComponent, RoutingFormComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule, DragDropModule, ScrollingModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RoutingModule {

}