import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser, Role } from '../../shared/models/user.model';
import { Subscription } from "rxjs";
import { UserService } from "../../shared/services/user.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    user: CurrentUser;
    private $user: Subscription;
    name: string = '';

    constructor(
        private userService: UserService,
        private router: Router,
    ) {

    }

    ngOnInit(): void {
        this.$user = this.userService.user.subscribe(user => {
            this.user = user;
            this.name = ProfileComponent.getInitials(user.fullName ? user.fullName : user.email);
        })
    }

    logout(): void {
        this.userService.toLogout();
    }

    ngOnDestroy() {
        this.$user.unsubscribe();
    }

    static getInitials(fullName): string {
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }
}
