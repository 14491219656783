import { EnvDef, defaults } from "./model";

export const environment: EnvDef = {
  production: true,
  releaseVersion: "%RELEASE_VERSION%",
  apiUrl: "https://api.mp.telqtele.com",
  frontendUrl: "https://app.mp.telqtele.com",
  keycloakUrl: "https://api.mp.telqtele.com/oauth2/authorization/keycloak",
  storagePrefix: defaults.storagePrefix,
  statusPageId: defaults.statusPageId,
  agGridLicenseKey: "%AG_GRID_LICENSE_KEY%",
  forcedUpdate: false,
  postponeUpdates: [
    "/live-testing",
    "/manual-testing",
    "/scheduler/tasks/create",
  ],
  recaptchaSiteKey: defaults.recaptchaSiteKey,
  recaptchaBaseUrl: defaults.recaptchaBaseUrl,
  ignoreUpdateUserIds: new Set([]),
};
