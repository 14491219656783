import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PendingUsersPageComponent } from "./pending-users-page/pending-users-page.component";
import { PendingUsersTableComponent } from "./pending-users-table/pending-users-table.component";
import { PendingUsersTableActionsRendererComponent } from "./pending-users-table/pending-users-table-actions-renderer.component";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { OrganizationsTableComponent } from "./organizations-table/organizations-table.component";
import { OrganizationsTableActionsRendererComponent } from "./organizations-table/organizations-table-actions-renderer.component";
import { OrganizationFormComponent } from './organization-form/organization-form.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrganizationsListComponent } from "./organizations-list/organizations-list.component";


@NgModule({
    declarations: [
        PendingUsersPageComponent,
        PendingUsersTableComponent,
        PendingUsersTableActionsRendererComponent,
        OrganizationsTableComponent,
        OrganizationsListComponent,
        OrganizationsTableActionsRendererComponent,
        OrganizationFormComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersModule {

}