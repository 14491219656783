import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    CellClickedEvent,
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { RoutingTableActionsRendererComponent } from "./routing-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import {
    RoutingAllRequestParams, RoutingSearch,
    RoutingUpdate,
    RoutingView,
    routingViewToRoutingUpdate
} from "../../shared/models/routing.model";
import { RoutingService } from "../../shared/services/routing.service";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: 'app-routing-table',
    templateUrl: './routing-table.component.html',
    styleUrls: [],
})
export class RoutingTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    gridApi: GridApi<RoutingView>;

    gridOptions: GridOptions<RoutingView> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`routing-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<RoutingView>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<RoutingView>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<RoutingView>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        domLayout: this.domLayout,
        columnDefs: [
            {
                headerName: 'Country',
                field: 'network.countryName',
                sortable: false,
            },
            {
                headerName: 'Network',
                field: 'network.operatorName',
                sortable: false,
            },
            {
                headerName: 'MCC/MNC',
                field: 'network.mcc',
                sortable: false,
                cellRenderer: (params: ICellRendererParams<RoutingView>) => {
                    const s = params.data.network;
                    return `<div class="one-line d-flex align-items-center gap-0_5">${s.mcc}/${s.mnc}</div>`;
                },
            },
            {
                headerName: 'Routing type',
                field: 'routingType',
                sortable: false,
            },
            {
                headerName: 'Routes',
                field: 'routes',
                sortable: false,
                minWidth: 250,
                cellRenderer: (params: ICellRendererParams<RoutingView>) => {
                    if (!params.data.routes.length) {return 'N/A';}
                    let content: string[] = [];
                    for (let r of params.data.routes) {
                        content.push(`${r.supplierName} (${r.productName}) ${this.currencyPipe.transform(r.price, r.currency, true, '1.0-4')}`);
                    }
                    return `<div class="one-line d-flex align-items-center gap-0_5">${content.join('<br>')}</div>`;
                },
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: RoutingTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: RoutingTableAction, params: ICellRendererParams<RoutingView>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: RoutingView[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('routingModalTpl', { read: TemplateRef, static: false }) routingModalTpl: any;
    routingModal: DialogRef;
    editModel: RoutingUpdate;

    @Input() filter: RoutingSearch;

    constructor(
        private userService: UserService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService,
        private routingService: RoutingService,
        private currencyPipe: CurrencyPipe
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`routing-table-columns-${userId}`, this.gridApi.getColumnState());
        });
        this.paginationPageSize = this.storage.getItem<number>(`routing-table-size-${userId}`, 20);
    }

    update() {
        this.loading = true;
        const params = new RoutingAllRequestParams()
        params.page = this.currentPageNumber - 1;
        params.size = this.paginationPageSize;
        if (this.filter) {
            params.networksIds = this.filter.networksIds;
            params.productsIds = this.filter.productsIds;
        }
        this.$update = this.routingService.all(params)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(page => {
                this.rows = page.content;
                this.totalRowsCount = page.totalElements;
                this.loading = false;
            });
    }

    private onAction(action: RoutingTableAction, params: ICellRendererParams<RoutingView>) {
        if (action === 'edit') {
            this.editModel = routingViewToRoutingUpdate(params.data);
            this.routingModal = this.modalService.alert().dialogClass('modal-dialog large-modal').component(this.routingModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.routingService.delete([params.data.id]).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Routing deleted', 'Routing');
                    this.update();
                });
            });
        }
    }

    createRouting() {
        this.editModel = this.routingService.create();
        this.routingModal = this.modalService.alert().dialogClass('modal-dialog large-modal').component(this.routingModalTpl).open();
    }

    onAfterSaveRouting() {
        this.routingModal.close();
        this.update();
    }

    changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`routing-table-size-${userId}`, size);
        this.update();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type RoutingTableAction = 'edit'|'delete';
export type RoutingTableActionFun = (action: RoutingTableAction, params: ICellRendererParams<RoutingView>) => void;