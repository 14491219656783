import { Title } from "@angular/platform-browser";
import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";
import { UserService } from "../../shared/services/user.service";
import { Role } from "../../shared/models/user.model";
import { fromEvent, filter, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { ProductsTableComponent } from "../products-table/products-table.component";

@Component({
    selector: 'app-products-page',
    templateUrl: 'products-page.component.html'
})
export class ProductsPageComponent implements AfterViewInit {

    isAdmin = false;

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('suppliersModalTpl', { read: TemplateRef, static: false }) suppliersModalTpl: any;
    @ViewChild(ProductsTableComponent, { static: false }) table: ProductsTableComponent;

    constructor(private titleService: Title, private modal: ModalService, private userService: UserService) {
        titleService.setTitle('Products');
        this.isAdmin = userService.user.value.roles.includes(Role.ADMIN);
    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement,'input')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.table.searchValue = this.searchInput.nativeElement.value.trim();
                    this.table.update();
                })
            ).subscribe();
    }

    showSuppliers() {
        this.modal.alert().component(this.suppliersModalTpl).open();
    }
}