import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { Country, NetworkUpdate, Operator } from "../../shared/models/network.model";
import { NetworkService } from "../../shared/services/network.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";

@Component({
  selector: 'app-network-form',
  templateUrl: './network-form.component.html',
  styleUrls: ['./network-form.component.scss']
})
export class NetworkFormComponent implements OnInit {

    @Input() model: NetworkUpdate;
    @Output() onAfterSave = new EventEmitter<NetworkUpdate>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    countriesLoading = false;
    countries: Country[] = [];
    operatorsLoading = false;
    operators: Operator[] = [];

    countryModel: Country;
    @ViewChild('countryModalTpl', { read: TemplateRef, static: false }) countryModalTpl: any;
    countryModal: DialogRef;

    operatorModel: Operator;
    @ViewChild('operatorModalTpl', { read: TemplateRef, static: false }) operatorModalTpl: any;
    operatorModal: DialogRef;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private networkService: NetworkService,
        private notifications: NotificationService,
        private modalService: ModalService
    ) {

    }

    ngOnInit(): void {
        this.updateCountries();
        this.updateOperators();
        this.form = this.formBuilder.group({
            id: [this.model.id],
            mcc: [this.model.mcc, V.compose([V.required, V.maxLength(3)])],
            mnc: [this.model.mnc, V.compose([V.required, V.maxLength(3)])],
            countryId: [this.model.countryId, V.compose([V.required])],
            operatorId: [this.model.operatorId,  V.compose([V.required])],
        });
        
    }

    updateCountries() {
        this.countriesLoading = true;
        this.networkService.allCountries().pipe(
            catchError(e => {
                this.countriesLoading = false;
                return throwError(() => e);
            })
        ).subscribe(countries => {
            this.countries = countries;
            this.countriesLoading = false;
        });
    }

    updateOperators() {
        this.operatorsLoading = true;
        this.networkService.allOperators().pipe(
            catchError(e => {
                this.operatorsLoading = false;
                return throwError(() => e);
            })
        ).subscribe(operators => {
            this.operators = operators;
            this.operatorsLoading = false;
        });
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as NetworkUpdate;
        this.networkService.saveNetwork(value).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Network ' + (this.model.id ? 'updated' : 'created'), 'Networks');
            this.onAfterSave.emit(org);
        });
    }

    createCountry() {
        this.countryModel = {
            id: null,
            name: '',
            isoAlpha2: '',
            isoAlpha3: '',
            mnpSupported: null,
            countryCallingCode: ''
        };
        this.countryModal = this.modalService.alert().component(this.countryModalTpl).open();
    }

    editCountry() {
        const id = parseInt(this.form.controls.countryId.value);
        const models = this.countries.filter(_ => _.id === id);
        if (!models.length) {return;}
        this.countryModel = models[0];
        this.countryModal = this.modalService.alert().component(this.countryModalTpl).open();
    }

    createOperator() {
        this.operatorModel = {
            id: null,
            name: '',
        };
        this.operatorModal = this.modalService.alert().component(this.operatorModalTpl).open();
    }

    editOperator() {
        const id = parseInt(this.form.controls.operatorId.value);
        const models = this.operators.filter(_ => _.id === id);
        if (!models.length) {return;}
        this.operatorModel = models[0];
        this.operatorModal = this.modalService.alert().component(this.operatorModalTpl).open();
    }

    onAfterSaveCountry(country: Country) {
        this.countryModal.close();
        this.updateCountryValue(country);
    }

    onAfterSaveOperator(operator: Operator) {
        this.operatorModal.close();
        this.updateOperatorValue(operator);
    }

    updateOperatorValue(model: Operator) {
        this.operatorModel = model;
        const existsModel = this.operators.filter(_ => _.id === model.id);
        if (existsModel.length) {
            existsModel.forEach(e => {
                e.name = model.name;
            });
        } else {
            this.operators.push(model);
        }
        this.form.controls.operatorId.patchValue(model.id);
    }

    updateCountryValue(model: Country) {
        this.countryModel = model;
        const existsModel = this.countries.filter(_ => _.id === model.id);
        if (existsModel.length) {
            existsModel.forEach(e => {
                e.name = model.name;
                e.countryCallingCode = model.countryCallingCode;
                e.mnpSupported = model.mnpSupported;
                e.isoAlpha2 = model.isoAlpha2;
                e.isoAlpha3 = model.isoAlpha3;
            });
        } else {
            this.countries.push(model);
        }
        this.form.controls.countryId.patchValue(model.id);
    }

}
