
import { Component } from '@angular/core';

export class BreadcrumbService {

    breadcrumbs: {label: string, url: any[] | string, active: boolean}[] = [];

    root(title: string, url: any[] | string, active = false) {
        this.breadcrumbs = [];

        this.add(title, url, active);

        return this;
    }

    add(title: string, url: any[] | string, active = false) {
        this.breadcrumbs.push({
            label: title,
            url: url,
            active: active
        });

        return this;
    }
}

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.html',
})

export class Breadcrumbs {

    constructor(public service: BreadcrumbService) {
       this.service = service;
    }

}