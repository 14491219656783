import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SessionsTableActionsRendererComponent } from "./sessions-table/sessions-table-actions-renderer.component";
import { SessionsTableComponent } from "./sessions-table/sessions-table.component";
import { SessionsPageComponent } from "./sessions-page/sessions-page.component";
import { SessionFormComponent } from "./session-form/session-form.component";


@NgModule({
    declarations: [
        SessionsTableActionsRendererComponent,
        SessionsTableComponent,
        SessionsPageComponent,
        SessionFormComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SessionsModule {

}