import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NetworksTableComponent } from "./networks-table/networks-table.component";
import { NetworksTableActionsRendererComponent } from "./networks-table/networks-table-actions-renderer.component";
import { NetworksPageComponent } from "./networks-page/networks-page.component";
import { CountriesTableComponent } from "./countries-table/countries-table.component";
import { CountriesTableActionsRendererComponent } from "./countries-table/countries-table-actions-renderer.component";
import { CountryFormComponent } from "./country-form/country-form.component";
import { OperatorFormComponent } from "./operator-form/operator-form.component";
import { PrefixFormComponent } from "./prefix-form/prefix-form.component";
import { NetworkFormComponent } from "./network-form/network-form.component";
import { PrefixesTableComponent } from "./prefixes-table/prefixes-table.component";
import { PrefixesTableActionsRendererComponent } from "./prefixes-table/prefixes-table-actions-renderer.component";


@NgModule({
    declarations: [
        NetworksTableComponent,
        NetworksTableActionsRendererComponent,
        NetworksPageComponent,
        CountriesTableComponent,
        CountriesTableActionsRendererComponent,
        CountryFormComponent,
        OperatorFormComponent,
        PrefixFormComponent,
        NetworkFormComponent,
        PrefixesTableComponent,
        PrefixesTableActionsRendererComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NetworksModule {

}