import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators as Vld } from '../../services/validation.service';

@Component({
    selector: 'input-duration',
    templateUrl: './input.duration.html',
    styleUrls: ['./input.duration.scss']
})

export class InputDuration implements OnInit {

    @Input() units: string[] = ['s', 'm', 'h', 'd'];
    @Input() size: string = '';
    @Input() placeholder = 'Enter value';
    @Input() require: boolean = false;
    @Input() validationService;
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Input() modelValue: number;
    @Input() modelUnit: string;
    @Input() disabled: boolean = false;
    @Input() unlimited = false;
    @Input() unlimitedValue = null;
    @Input() inputClass: string;
    control: FormControl;
    labels: Object = {
        s: 'Seconds',
        m: 'Minutes',
        h: 'Hours',
        d: 'Days',
        unlimited: 'Unlimited'
    };

    private model: number;

    ngOnInit() {
        let validators = [];
        if (this.require) {
            validators.push(Vld.min(1))
        }
        validators.push(control => {
            if (this.modelValue && this.units.indexOf(this.modelUnit) === -1) {
                return {custom: {
                        message: 'Select unit'
                    }};
            }
            return null;
        });
        this.control = new FormControl();
        this.control.setValidators(Vld.compose(validators));
        this.control.valueChanges.subscribe(from => {
            this.setValue(this.control.value);
        });
        if (this.modelUnit) {
            let unit = this.modelUnit;
            this.setUnit(unit);
            this.modelUnit = unit;
        }
        if (this.modelValue) {
            this.setValue(this.modelValue);
            this.model = this.modelValue;
        }
        if (this.modelValue !== null) {
            this.control.patchValue(this.modelValue);
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.modelUnit === 'unlimited') {
            event.preventDefault();
            return;
        }

        if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+C
            (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+V
            (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+X
            (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
            // Allow: home, end, left, right
            (event.keyCode >= 35 && event.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
            event.preventDefault();
        }
    }

    onChangeData() {
        this.change.emit({
            value: this.modelValue,
            unit: this.modelUnit
        });
    }

    setValue(value) {
        this.modelValue = parseInt(value);
        this.onChangeData();
    }

    setUnit(unit) {
        if (unit === this.modelUnit) {
            return;
        }
        if (unit === 'unlimited') {
            this.modelValue = this.unlimitedValue
        }
        this.modelUnit = unit;
        this.control.patchValue(this.modelValue);
        this.onChangeData();
    }
}
