
import { debounceTime } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'input-search-period',
    styleUrls: ['input.search.datePeriod.scss'],
    templateUrl: './input.search.datePeriod.html'
})

export class InputSearchDatePeriod implements OnInit {

    @Input() customClass: string = ''
    @Input() buttonCancelClasses: string = '';
    @Input() type: string = 'date';
    @Input() start: number | string;
    @Input() end: number | string;
    @Input() loading: boolean = false;
    @Input() timepicker: boolean = false;
    @Input() autoApply: boolean = true;
    @Input() delay: number = 500;
    @Input() placeholder = 'Date';
    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() tz = 'UTC';
    @Input() ranges: DatePeriodRanges = {
        'Today': [moment().startOf('day'), moment().endOf('day').set({ hour: 23, minute: 59 })],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days').set({ hour: 23, minute: 59 })],
        'Last 7 Days': [moment().subtract(6, 'days'), moment().set({ hour: 23, minute: 59 })],
        'Last 30 Days': [moment().subtract(29, 'days'), moment().set({ hour: 23, minute: 59 })],
        'This Month': [moment().startOf('month'), moment().endOf('month').set({ hour: 23, minute: 59 })],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month').set({ hour: 23, minute: 59 })],
    };
    @Output() change: EventEmitter<any> = new EventEmitter();

    model: string = '';
    control: FormControl = new FormControl();
    format: string = 'DD/MM/YYYY';
    separator: string = ' - ';
    showPlaceHolder: boolean = false;
    input: any;

    constructor(public elementRef: ElementRef) { }

    ngOnInit() {
        const tzStr = moment().format('Z');
        if (this.timepicker) {
            this.format = 'DD/MM/YYYY HH:mm';
        }
        let old = { start: 0, end: 0 };
        this.control.valueChanges.pipe(debounceTime(this.delay)).subscribe(from => {
            this.change.emit(this.control.value);
        });
        let $root = $(this.elementRef.nativeElement);
        this.input = $root.find('.date-range-control');
        let startDate = this.start ? moment(<number>this.start) : moment().startOf('day').set({ hour: 0, minute: 0 });
        let endDate = this.end ? moment(<number>this.end) : moment().endOf('day').set({ hour: 23, minute: 59 });

        let changeData = {
            start: startDate.clone().unix(),
            end: endDate.clone().unix(),
            startFormatStr: startDate.clone().format(this.format),
            endFormatStr: endDate.clone().format(this.format),
            startStr: startDate.clone().format('YYYY-MM-DDTHH:mm:ss'),
            endStr: endDate.clone().format('YYYY-MM-DDTHH:mm:ss'),
            startIso: startDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            endIso: endDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            startStrTz: startDate.clone().format("YYYY-MM-DDTHH:mm:ss") + tzStr,
            endStrTz: endDate.clone().format("YYYY-MM-DDTHH:mm:ss") + tzStr,
        };

        this.input.daterangepicker({
            format: this.format,
            showDropdowns: true,
            showWeekNumbers: false,
            timePicker: this.timepicker,
            timePickerIncrement: 1,
            timePicker24Hour: true,
            alwaysShowCalendars: true,
            opens: 'right',
            drops: 'down',
            buttonClasses: ['btn', 'btn-sm'],
            applyClass: 'btn-primary',
            cancelClass: 'btn-default ' + this.buttonCancelClasses,
            separator: this.separator,
            startDate: startDate,
            endDate: endDate,
            autoApply: this.autoApply,
            autoUpdateInput: true,
            ranges: this.ranges,
            minDate: this.minDate,
            maxDate: this.maxDate,
            locale: {
                format: this.format,
                applyLabel: 'Submit',
                cancelLabel: 'Clear',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 1
            }
        }, (start, end, label) => {
            changeData.startStr = start.format("YYYY-MM-DDTHH:mm:ss");
            changeData.endStr = end.format("YYYY-MM-DDTHH:mm:ss");
            changeData.startIso = start.clone().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            changeData.endIso = end.clone().utc().format("YYYY-MM-DDTHH:mm:ss.999[Z]");
            changeData.start = start.clone().unix();
            changeData.end = end.clone().unix();
            changeData.startFormatStr = start.clone().format(this.format);
            changeData.endFormatStr = end.clone().format(this.format);
            changeData.startStrTz = start.format("YYYY-MM-DDTHH:mm:ss") + tzStr;
            changeData.endStrTz = end.format("YYYY-MM-DDTHH:mm:ss") + tzStr;
        }).on('apply.daterangepicker', (ev, picker) => {
            // if (changeData.start !== old.start || changeData.end !== old.end) {
            this.change.emit(changeData);
            old.start = changeData.start;
            old.end = changeData.end;
            // }
        }).on('hide.daterangepicker', (ev, picker) => {
            this.showPlaceHolder = false;
        }).on('show.daterangepicker', (ev, picker) => {
            // this.showPlaceHolder = true;
        }).on('cancel.daterangepicker', (ev, picker) => {
            this.input.data('daterangepicker').setStartDate(new Date());
            this.input.data('daterangepicker').setEndDate(new Date());
            this.input.val('');
            changeData.startStr = null;
            changeData.endStr = null;
            Object.keys(changeData).forEach(key => changeData[key] = null);
            this.change.emit(changeData);
        });
        this.updateValue();
    }

    reset(): void {
        this.input.data('daterangepicker').setStartDate(new Date());
        this.input.data('daterangepicker').setEndDate(new Date());
        this.input.val('');
    }

    updateValue(startDate = null, endDate = null) {
        let text = [];
        if (startDate) {
            this.start = startDate;
        }
        if (endDate) {
            this.end = endDate;
        }
        if (this.start) {
            let date = moment.unix(<number>this.start).format(this.format);
            this.input.data('daterangepicker').setStartDate(date);
            text.push(date);
        }
        if (this.end) {
            let date = moment.unix(<number>this.end).format(this.format);
            this.input.data('daterangepicker').setEndDate(date);
            text.push(date);
        }
        if (text.length) {
            this.model = text.join(this.separator);
            this.control.patchValue(this.model);
        }
    }
}

export interface DatePeriodRanges {
    [key: string]: any[];
}
