import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { NetworksTableActionsRendererComponent } from "./networks-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { NetworkService } from "../../shared/services/network.service";
import { Network, NetworkUpdate } from "../../shared/models/network.model";

@Component({
    selector: 'app-networks-table',
    templateUrl: './networks-table.component.html',
    styleUrls: [],
})
export class NetworksTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    gridApi: GridApi<Network>;

    gridOptions: GridOptions<Network> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`networks-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<Network>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<Network>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<Network>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        domLayout: this.domLayout,
        columnDefs: [
            {
                headerName: 'Country',
                field: 'country',
                sortable: false,
                valueFormatter: (params) => params.data.country ?  params.data.country.name : 'N/A',
            },
            {
                headerName: 'Operator',
                field: 'operator',
                sortable: false,
                valueFormatter: (params) => params.data.operator ?  params.data.operator.name : 'N/A',
            },
            {
                headerName: 'MCC',
                field: 'mcc',
                valueGetter: (params) => params.data.mcc,
                sortable: false,
                initialWidth: 150,
            },
            {
                headerName: 'MNC',
                field: 'mnc',
                valueGetter: (params) => params.data.mnc,
                sortable: false,
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: NetworksTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: NetworksTableAction, params: ICellRendererParams<Network>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: Network[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('networkModalTpl', { read: TemplateRef, static: false }) networkModalTpl: any;
    networkModal: DialogRef;
    editModel: NetworkUpdate;

    @ViewChild('prefixesModalTpl', { read: TemplateRef, static: false }) prefixesModalTpl: any;

    searchValue = '';

    constructor(
        private userService: UserService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService,
        private networkService: NetworkService
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`networks-table-columns-${userId}`, this.gridApi.getColumnState());
        });
        this.paginationPageSize = this.storage.getItem<number>(`networks-table-size-${userId}`, 20);
    }

    update() {
        this.loading = true;
        this.$update = this.networkService.allNetworks(this.currentPageNumber - 1, this.paginationPageSize, this.searchValue)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(page => {
                this.rows = page.content;
                this.totalRowsCount = page.totalElements;
                this.loading = false;
            });
    }

    private onAction(action: NetworksTableAction, params: ICellRendererParams<Network>) {
        if (action === 'edit') {
            this.editModel = {
                id: params.data.id,
                mcc: params.data.mcc,
                mnc: params.data.mnc,
                countryId: params.data.country.id,
                operatorId: params.data.operator.id
            };
            this.networkModal = this.modalService.alert().component(this.networkModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.networkService.deleteNetwork(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Network deleted', 'Networks');
                    this.update();
                });
            });
        }
        if (action === 'prefixes') {
            this.editModel = {
                id: params.data.id,
                mcc: params.data.mcc,
                mnc: params.data.mnc,
                countryId: params.data.country.id,
                operatorId: params.data.operator.id
            };
            this.modalService.alert().component(this.prefixesModalTpl).open();
        }
    }

    createNetwork() {
        this.editModel = {
            id: null,
            mcc: '',
            mnc: '',
            countryId: null,
            operatorId: null
        };
        this.networkModal = this.modalService.alert().component(this.networkModalTpl).open();
    }

    onAfterSaveNetwork() {
        this.networkModal.close();
        this.update();
    }

    changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`networks-table-size-${userId}`, size);
        this.update();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type NetworksTableAction = 'edit'|'delete'|'prefixes';
export type NetworksTableActionFun = (action: NetworksTableAction, params: ICellRendererParams<Network>) => void;