import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import { GridApi, GridOptions } from "ag-grid-community";
import { Organization } from "../../shared/models/user.model";
import { UserService } from "../../shared/services/user.service";
import { catchError, Subscription, throwError } from "rxjs";

@Component({
    selector: 'app-organizations-list',
    templateUrl: './organizations-list.component.html',
})
export class OrganizationsListComponent implements OnInit, OnDestroy {

    theme = AgGridUtils.theme;
    gridApi: GridApi<Organization>;

    gridOptions: GridOptions<Organization> = {
        onGridReady: e => {
            this.gridApi = e.api;
            this.update();
        },
        columnDefs: [
            {
                headerName: 'Name',
                valueGetter: (params) => params.data.name,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                resizable: false,
                getQuickFilterText: params => params.data.name,
                cellRenderer: params => '<div class="pointer">' + params.data.name + '</div>',
                onCellClicked: e => this.onSelect.emit(e.data)
            },
        ],
        headerHeight: 0,
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    };

    rows: Organization[] = [];
    loading = false;
    private $update: Subscription;

    @Output() onSelect = new EventEmitter<Organization>();

    constructor(private userService: UserService) {
    }

    ngOnInit() {
    }

    update() {
        this.loading = true;
        this.$update = this.userService.allOrganizations()
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                this.rows = rows;
                this.loading = false;
            });
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}