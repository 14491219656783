
export interface Supplier {
    id?: number;
    name?: string;
    createdAt?: string;
}

export class SupplierAllRequestParams {

    size: number = 20;
    page: number = 0;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}