export enum Role {
    ADMIN = 'ADMIN',
    USER = 'USER'
}

export interface CurrentUser {
    id: string
    email: string
    emailVerified: boolean
    enabled: boolean
    nickName: string
    fullName?: string
    roles: Role[]
}

export interface Organization {
    id?: number,
    name: string,
    search?: string
}

export interface PlatformUser {
    id: string,
    email: string,
    emailVerified: boolean,
    createdAt: string|Date,
    updatedAt?: string|Date,
    firstName?: string,
    lastName?: string,
    organizationId?: number,
    organization?: Organization,
    hidden: boolean
}