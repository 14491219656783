
import {debounceTime} from 'rxjs/operators';

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
    selector: 'input-search',
    templateUrl: './input.search.html'
})

export class InputSearch implements OnInit {

    @Input() loading: boolean = false;
    @Input() delay: number = 500;
    @Input() placeholder = 'Search';
    @Output() textChange: EventEmitter<any> = new EventEmitter();
    model: string = '';
    control: FormControl = new FormControl();

    ngOnInit() {
        this.control.valueChanges.pipe(debounceTime(this.delay)).subscribe(from => {
            this.textChange.emit(this.control.value.trim());
        });
    }

    setVal(str) {
        this.control.patchValue(str);
    }
}