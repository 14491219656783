import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organization } from "../../shared/models/user.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { UserService } from "../../shared/services/user.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html'
})
export class OrganizationFormComponent implements OnInit {

    @Input() model: Organization;
    @Output() onAfterSave = new EventEmitter<Organization>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    constructor(
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        private userService: UserService,
        private notifications: NotificationService
    ) {
        this.form = formBuilder.group({
            name: ['', V.compose([V.required, V.minLength(2), V.maxLength(255)])],
        });
    }

    ngOnInit(): void {
        if (!this.model) {
            this.model = {
                id: null,
                name: ''
            };
        }
    }

    onSave() {
        this.loading = true;
        this.userService.saveOrganization(this.model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Organization ' + (this.model.id ? 'updated' : 'created'), 'Users');
            this.onAfterSave.emit(org);
        });
    }

}
