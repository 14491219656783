
import { Pipe } from '@angular/core';

@Pipe({ name: 'size' })

export class FileSizePipe {

    transform(value: number, decimalPoint: number = 2): string {
        if (value === 0){
            return '0 Bytes';
        }

        let k = 1024,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(value) / Math.log(k));

        return parseFloat((value / Math.pow(k, i)).toFixed(decimalPoint)) + ' ' + sizes[i];
    }
}