import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {StatusPageStatus, StatusPageSummary} from "../models/status-page-status.model";

@Injectable()
export class StatusPageService {
    sp: any;
    constructor(@Inject('STATUSPAGE_ID') statusPageId: string) {
        // @ts-ignore
        this.sp = new StatusPage.page({page: statusPageId});
    }

    status(): Observable<StatusPageStatus> {
        return new Observable(o => {
            this.sp.status({
                success : function(res) {
                    o.next({
                        url: res.page.url,
                        updatedAt: new Date(res.page.updated_at),
                        description: res.status.description,
                        indicator: res.status.indicator
                    });
                }
            });
        });
    }

    summary(): Observable<StatusPageSummary> {
        return new Observable(o => {
            this.sp.summary({
                success : function(res) {
                    const status = {
                        url: res.page.url,
                        updatedAt: new Date(res.page.updated_at),
                        description: res.status.description,
                        indicator: res.status.indicator
                    };
                    let components = [];
                    res.components.map(com => {
                        if (com.group_id) {
                            return;
                        }
                        components.push({
                            name: com.name,
                            status: com.status,
                            updatedAt: new Date(com.updated_at)
                        });
                    });
                    let incidents = [];
                    res.incidents.map(inc => {
                        const lastUpdate = inc.incident_updates[0];
                        incidents.push({
                            name: inc.name,
                            status: inc.status,
                            description: lastUpdate.body,
                            impact: inc.impact,
                            url: inc.shortlink,
                            createdAt: new Date(inc.started_at),
                            updatedAt: new Date(lastUpdate.updated_at)
                        });
                    });
                    let maintenances = [];
                    res.scheduled_maintenances.map(maintenance => {
                        const lastUpdate = maintenance.incident_updates[0];
                        const mFor = Date.parse(maintenance.scheduled_for) / 1000;
                        const mUntil = Date.parse(maintenance.scheduled_until) / 1000;
                        let minutes = 0;
                        if (mUntil - mFor <= 3600) {
                            minutes = (mUntil - mFor) / 60;
                        }
                        maintenances.push({
                            name: maintenance.name,
                            status: maintenance.status,
                            description: lastUpdate.body,
                            url: maintenance.shortlink,
                            createdAt: new Date(maintenance.started_at),
                            updatedAt: new Date(lastUpdate.updated_at),
                            for: new Date(maintenance.scheduled_for),
                            until: new Date(maintenance.scheduled_until),
                            minutes: minutes ? parseInt(String(minutes)) : 0
                        });
                    });
                    o.next({
                        status: status,
                        components: components,
                        incidents: incidents,
                        maintenances: maintenances
                    });
                }
            });
        });
    }
}