import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    transform(value: number): string {
        let hours = Math.floor(value / 3600);
        let minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = value - (hours * 3600) - (minutes * 60);

        let parts = [];
        if (hours) {
            parts.push(hours + 'h');
        }
        if (minutes) {
            parts.push(minutes + 'm');
        }
        if (seconds || !parts.length) {
            parts.push(seconds + 's');
        }

        return parts.join(' ');
    }
}