import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { Prefix } from "../../shared/models/network.model";
import { NetworkService } from "../../shared/services/network.service";

@Component({
  selector: 'app-prefix-form',
  templateUrl: './prefix-form.component.html'
})
export class PrefixFormComponent implements OnInit {

    @Input() model: Prefix;
    @Output() onAfterSave = new EventEmitter<Prefix>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private networkService: NetworkService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [this.model.id],
            prefix: [this.model.prefix, V.compose([V.required, V.maxLength(250)])],
            mcc: [this.model.mcc, V.compose([V.required])],
            mnc: [this.model.mnc, V.compose([V.required])],
        });
    }

    onSave() {
        this.loading = true;
        const value = this.form.value as Prefix;
        this.networkService.savePrefix(value).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(org => {
            this.loading = false;
            this.notifications.success('Prefix ' + (this.model.id ? 'updated' : 'created'), 'Networks');
            this.onAfterSave.emit(org);
        });
    }

}
