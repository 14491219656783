import { Component, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { DropdownCtx } from "../../shared/directives/dropdown.directive";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../shared/filter/filter";
import { InputSearchDatePeriod } from "../../shared/components/input/input.search.datePeriod";
import { SmsSearch } from "../../shared/models/sms.model";
import { SmsService } from "../../shared/services/sms.service";

@Component({
    selector: 'app-sms-filter',
    templateUrl: 'sms-filter.component.html',
    styleUrls: ['../../shared/filter/filter.scss'],
})

export class SmsFilterComponent {

    @Input() model: SmsSearch = {
        createdAfter: null,
        createdBefore: null,
        deliveryStatus: null,
        recipientId: null,
        senderId: null,
        textContent: null
    };

    @Output() onSubmit: EventEmitter<SmsSearch> = new EventEmitter();

    @ViewChild('dateInput', {read: InputSearchDatePeriod, static: false}) dateInput: InputSearchDatePeriod;

    loading = false;

    filters: IFilterDef[] = [
        { title: 'Date of processing', show: true, order: 1 },
        { title: 'Sender ID', show: true, order: 2 },
        { title: 'Recipient', show: true, order: 3 },
        { title: 'Status', show: true, order: 4 },
        { title: 'Text', show: false, order: 5 },
    ];
    private readonly filterStorageKey = 'sms-filter';

    deliveredStatuses = SmsService.deliveredStatuses;

    constructor(private storage: LocalStorage) {
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
                if (f.title === 'Date of processing' && this.dateInput) { this.dateInput.reset(); }
            }
        });
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onSubmitClick() {
        this.onSubmit.emit(this.model);
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Date of processing':
                if (value === '') {
                    this.model.createdAfter = null;
                    this.model.createdBefore = null;
                    filter.value = '';
                } else if (value.startIso && value.endIso) {
                    this.model.createdAfter = value.startIso;
                    this.model.createdBefore = value.endIso;
                    filter.value = value.startFormatStr + ' ' + value.endFormatStr;
                }
                break;
            case 'Recipient':
                this.model.recipientId = value
                filter.value = this.model.recipientId;
                break;
            case 'Status':
                filter.value = value;
                this.model.deliveryStatus = value ? value : null;
                break;
            case 'Sender ID':
                filter.value = value;
                this.model.senderId = value ? value : null;
                break;
            case 'Text':
                filter.value = value;
                this.model.textContent = value ? value : null;
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex)
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}