import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { Organization, PlatformUser } from "../../shared/models/user.model";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { DatePipe } from "@angular/common";
import { catchError, Subscription, throwError } from "rxjs";
import { PendingUsersTableActionsRendererComponent } from "./pending-users-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
    selector: 'app-pending-users-table',
    templateUrl: './pending-users-table.component.html',
    styleUrls: [],
})
export class PendingUsersTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    gridApi: GridApi<PlatformUser>;

    gridOptions: GridOptions<PlatformUser> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`pending-users-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<PlatformUser>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<PlatformUser>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<PlatformUser>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        domLayout: this.domLayout,
        columnDefs: [
            {
                headerName: 'Created',
                valueGetter: (params) => params.data.createdAt,
                valueFormatter: (params) => this.datePipe.transform(params.data.createdAt, 'MMMM d, y, h:mm'),
                sortable: false,
                initialWidth: 150,
            },
            {
                headerName: 'Email',
                field: 'email',
                sortable: false,
            },
            {
                headerName: 'Name',
                field: 'firstName',
                sortable: false,
                valueFormatter: (params) => params.data.firstName + ' ' + params.data.lastName,
            },
            {
                headerName: 'Organization',
                field: 'organization',
                sortable: true,
                valueFormatter: (params) => {
                    if (params.data.organization) {
                        return params.data.organization.name;
                    }
                    if (params.data.organizationId) {
                        return `Org: ${params.data.organizationId}`;
                    }
                    return 'N/A';
                },
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: PendingUsersTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: PendingUsersTableAction, params: ICellRendererParams<PlatformUser>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: PlatformUser[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('organizationsModalTpl', { read: TemplateRef, static: false }) organizationsModalTpl: any;
    organizationsModal: DialogRef;
    editUser: PlatformUser;

    constructor(
        private userService: UserService,
        private storage: LocalStorage,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`pending-users-table-columns-${userId}`, this.gridApi.getColumnState());
        });
        this.paginationPageSize = this.storage.getItem<number>(`pending-users-table-size-${userId}`, 20);
    }

    update() {
        this.loading = true;
        this.$update = this.userService.pendingUsers(this.currentPageNumber - 1, this.paginationPageSize)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(page => {
                this.rows = page.content;
                this.totalRowsCount = page.totalElements;
                this.loading = false;
            });
    }

    private onAction(action: PendingUsersTableAction, params: ICellRendererParams<PlatformUser>) {
        if (action === 'approve') {
            this.editUser = params.data;
            this.organizationsModal = this.modalService.alert().component(this.organizationsModalTpl).open();
        }
        if (action === 'decline') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.userService.hideUser(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('User deleted', 'Users');
                    this.update();
                });
            });
        }
    }

    changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`pending-users-table-size-${userId}`, size);
        this.update();
    }

    onSelectOrg(org: Organization) {
        this.organizationsModal.close();
        this.loading = true;
        this.userService.assignOrganization(this.editUser.id, org.id).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(() => {
            this.notifications.success('User ' + this.editUser.email + ' approved', 'Users');
            this.update();
        });
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type PendingUsersTableAction = 'approve'|'decline';
export type PendingUsersTableActionFun = (action: PendingUsersTableAction, params: ICellRendererParams<PlatformUser>) => void;