import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { CountriesTableActionFun } from "./countries-table.component";
import { Country } from "../../shared/models/network.model";

@Component({
    selector: 'app-countries-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-edit" tooltip="Edit" (click)="params.onAction('edit', params)"></i>
            <i class="cp icon-delete" tooltip="Delete" (click)="params.onAction('delete', params)"></i>
        </div>
    `,
})
export class CountriesTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<Country> & {onAction: CountriesTableActionFun};

    agInit(params: ICellRendererParams<Country> & {onAction: CountriesTableActionFun}) {
        this.params = params;
    }

    refresh(params: ICellRendererParams<Country>): boolean {
        return false;
    }
}