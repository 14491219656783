import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    CellClickedEvent,
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { DatePipe } from "@angular/common";
import { catchError, Subscription, throwError } from "rxjs";
import { SmsTableActionsRendererComponent } from "./sms-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { SmsService } from "../../shared/services/sms.service";
import { Sms, SmsSearch } from "../../shared/models/sms.model";
import { ValueFormatterParams } from "ag-grid-community/dist/lib/entities/colDef";

@Component({
    selector: 'app-sms-table',
    templateUrl: './sms-table.component.html',
    styleUrls: ['./sms-table.component.scss'],
})
export class SmsTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    gridApi: GridApi<Sms>;

    gridOptions: GridOptions<Sms> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`sms-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<Sms>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<Sms>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<Sms>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        domLayout: this.domLayout,
        columnDefs: [
            {
                headerName: 'Message ID',
                field: 'id',
                maxWidth: 120,
                sortable: false,
            },
            {
                headerName: 'Date of processing',
                field: 'createdAt',
                //sortable: true,
                //comparator: () => 0,
                valueFormatter: (params: ValueFormatterParams<Sms>) => {
                    return this.datePipe.transform(params.data.createdAt, 'medium')
                },
            },
            {
                headerName: 'Sender ID',
                field: 'senderId',
            },
            {
                headerName: 'Recipient',
                field: 'recipientId',
                sortable: false,
            },
            {
                headerName: 'Network',
                field: 'networkDetails',
                minWidth: 250,
                sortable: false,
                valueFormatter: (params: ValueFormatterParams<Sms>) => {
                    const n = params.data.networkDetails;
                    if (!n) { return 'N/A'; }
                    return `${n.countryName}(${n.mcc})/${n.operatorName}(${n.mnc})`
                }
            },
            {
                headerName: 'Supplier',
                field: 'supplier',
                sortable: false,
                valueFormatter: (params: ValueFormatterParams<Sms>) => {
                    const s = params.data.supplier;
                    if (!s) { return 'N/A'; }
                    return s.name;
                }
            },
            {
                headerName: 'Status',
                field: 'deliveryStatus',
                sortable: false
            },
            {
                headerName: 'Action',
                maxWidth: 100,
                minWidth: 100,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: SmsTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: SmsTableAction, params: ICellRendererParams<Sms>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: Sms[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('detailsModalTpl', { read: TemplateRef, static: false }) detailsModalTpl: any;
    detailsModal: DialogRef;
    smsModel: Sms;

    private lastRow: Sms;
    search: SmsSearch;

    constructor(
        private userService: UserService,
        private smsService: SmsService,
        private storage: LocalStorage,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`sms-table-columns-${userId}`, this.gridApi.getColumnState());
        });
    }

    update(resetPage = false) {
        this.loading = true;
        if (resetPage) { this.lastRow = null; }
        this.$update = this.smsService.all(20, this.lastRow ? this.lastRow.createdAt : null, this.search)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                rows = rows.sort((a, b) => b.id - a.id);
                if (rows.length) {
                    this.lastRow = rows[rows.length - 1];
                }
                if (resetPage) {
                    this.rows = rows;
                } else {
                    rows.forEach(r => this.rows.push(r));
                }
                this.gridApi.setGridOption("rowData", this.rows);

                this.loading = false;
            });
    }

    private onAction(action: SmsTableAction, params: ICellRendererParams<Sms>) {
        if (action === 'details') {
            this.smsModel = params.data;
            this.modalService.alert().dialogClass('modal-dialog large-modal').component(this.detailsModalTpl).open();
        }
    }

    changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`sms-table-size-${userId}`, size);
        this.update();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type SmsTableAction = 'details';
export type SmsTableActionFun = (action: SmsTableAction, params: ICellRendererParams<Sms>) => void;