import { Component } from '@angular/core';
import { NotificationService, Notification } from '../../services/notification.service';

@Component({
    selector: 'app-notifications-widget',
    templateUrl: 'notifications-widget.component.html',
    styleUrls: ['notifications-widget.component.scss']
})
export class NotificationsWidgetComponent {

    defaultNotification: Notification = {
        type: 'success',
        ttl: 5000,
        title: '',
        content: ''
    }

    idCounter = 0;
    notifications: ViewNotification[] = [];
    typeToIcon = {
        success: 'icon-positive',
        warning: 'icon-warning',
        error: 'icon-negative',
        info: 'icon-info-1'
    }

    constructor(service: NotificationService) {
        service.notifications.subscribe(n => {
            this.idCounter++;
            const type = n.type ? n.type.toLowerCase() : this.defaultNotification.type;
            const notification: ViewNotification = {
                id: this.idCounter,
                type: type,
                ttl: n.ttl ? n.ttl : this.defaultNotification.ttl,
                title: n.title ? n.title : this.defaultNotification.title,
                content: n.content,
                hover: false,
                needRemove: false,
                hidden: false,
                icon: this.typeToIcon[type],
            };
            if (notification.ttl) {
                setTimeout(() => this.remove(notification), notification.ttl);
            }
            this.notifications.push(notification);
        })
    }

    mouseEnter(notification: ViewNotification): void {
        notification.hover = true;
    }

    mouseLeave(notification: ViewNotification): void {
        notification.hover = false;
        if (notification.needRemove) {
            this.remove(notification);
        }
    }

    remove(notification: ViewNotification, force = false): void {
        if (!force && notification.hover) {
            notification.needRemove = true;
            return;
        }

        notification.hidden = true;

        setTimeout(() => {
            this.notifications = this.notifications.filter(_ => _.id !== notification.id);
            if (!this.notifications.length) {
                this.idCounter = 0;
            }
        }, 100);
    }
}

interface ViewNotification extends Notification {
    id: number;
    hover: boolean;
    needRemove: boolean;
    icon: string;
    hidden: boolean;
}


