import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-table',
    templateUrl: 'table.component.html',
    encapsulation: ViewEncapsulation.None
})

export class TableComponent {
    @Input() batch: boolean = false;
    @Input() actions: ActionDef[] = [];
    @Input() columns: ColumnDef[] = [];
    @Input() rows: RowDef[] = [];

    @Output() action: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() cellClick: EventEmitter<CellClickEvent> = new EventEmitter();
    @Output() changeBatch: EventEmitter<RowDef[]> = new EventEmitter();
    @Output() sort: EventEmitter<SortEvent[]> = new EventEmitter();

    batchValue: boolean = false;

    /**
     * Maximum number of actions to display in actions column without dropdown menu
     * 0 - means all actions will be displayed
     */
    @Input() maxDisplayActions = 3;

    constructor() { }

    getColumnsLength(): number {
        return this.columns.length + (this.batch ? 1 : 0) + (this.actions.length ? 1 : 0);
    }

    onBatchAll(): void {
        this.rows.filter(_ => !_.batchDisabled).forEach(r => r.batch = this.batchValue);
        this.changeBatch.emit(this.rows.filter(r => r.batch));
    }

    onBatch(): void {
        const batchRows = this.rows.filter(_ => _.batch);
        this.batchValue = this.rows.length > 0 && batchRows.length === this.rows.length;
        this.changeBatch.emit(batchRows);
    }

    onSort(column: ColumnDef): void {
        if (!column.sort) {
            return;
        }
        const sortDefinitions: Object = {
            'asc': 'desc',
            'desc': '',
            '': 'asc'
        };
        column.sortDirection = sortDefinitions[column.sortDirection]
        this.sort.emit(
            this.columns.filter(_ => _.sort && _.sortDirection.length).map(column => {
                return {
                    prop: column.prop,
                    direction: column.sortDirection
                }
            })
        )
    }

    onAction(actionDef: ActionDef, row: RowDef): void {
        this.action.emit({
            name: actionDef.name,
            row: row,
            column: null
        })
    }

    onCellClick(column: ColumnDef, row: RowDef): void {
        this.cellClick.emit({
            column: column,
            row: row
        })
    }

    onSwitch(column: ColumnDef, row: RowDef): void {
        this.action.emit({
            name: 'switch',
            row: row,
            column: column
        });
    }

    isActionShow(action: ActionDef, row: RowDef): boolean {
        if (!action.show) {
            return true;
        }
        return action.show(row.data) === true;
    }

    extractClassName(classDef: string | Function, argArray = []): string {
        if (!classDef) {
            return '';
        }

        if (typeof classDef === 'function') {
            let value = classDef.apply(null, argArray);
            return typeof value === 'string' ? value : '';
        }
        return classDef;

    }

    extractValue(column: ColumnDef, row: RowDef): string {
        if (column.format) {
            return column.format.apply(null, [row.data]);
        }
        return (typeof row.data[column.prop] !== 'undefined') ? row.data[column.prop] : '';
    }

    getVisibleTableActions(row: RowDef): number {
        const visibleCount = this.actions.filter(_ => {
            if (_.show) {
                return _.show(row);
            }
            return true;
        }).length;
        return visibleCount;
    }
}

export class ColumnTypes {
    static HTML = 'html';
    static TEXT = 'text';
    static INPUT_SWITCH = 'input-switch';
    static DATE = 'date';
}

export interface ColumnDef {
    title: string,
    type?: string,
    prop: string,
    format?: Function,
    popover?: string,
    sort?: boolean,
    sortDirection?: string,
    className?: string | Function,
    cellClassName?: string | Function,
    width?: string
}

export interface RowDef {
    _loading?: any;
    _child?: any;
    batch: boolean,
    batchDisabled: boolean,
    className?: string | Function,
    data: any,
    tables?: {
        rows: RowDef[],
        columns: ColumnDef[],
    }[]
}

export interface ActionDef {
    icon: string,
    iconExtraData?: string,
    name: string,
    title?: string,
    show?: Function,
}

export interface ActionEvent {
    name: string,
    column: ColumnDef,
    row: RowDef,
}

export interface CellClickEvent {
    column: ColumnDef,
    row: RowDef,
}

export interface SortEvent {
    prop: string,
    direction: string
}