import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from "../../shared/services/user.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-pending-page',
    templateUrl: './pending-page.component.html',
    styleUrls: ['./pending-page.component.html']
})
export class PendingPageComponent implements OnInit, OnDestroy {

    private scheduler;

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit() {
        this.scheduler = setInterval(() => {
            this.update();
        }, 1000 * 60);
        this.update();
    }

    private update() {
        this.userService.currentUser().subscribe(u => {
            if (u.enabled) {
                this.router.navigate(['/sms']).then();
            }
        });
    }

    ngOnDestroy() {
        clearInterval(this.scheduler);
    }
}