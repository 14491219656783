import {Component, OnInit} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-tour',
    templateUrl: './tour.component.html'
})

export class TourComponent implements OnInit {

    currentTour: string = null;

    constructor(public router: Router) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentTour = this.getTourByUrl(event.urlAfterRedirects);
            }
        });
        this.currentTour = this.getTourByUrl(window.location.hash.replace('#', ''));
    }

    onClickTour() {
        // @ts-ignore: Introjs no typings
        let intro = introJs();
        intro.setOptions({
            steps: this.collection[this.currentTour],
            nextLabel: 'Next &rarr;',
            prevLabel: '&larr; Back',
            skipLabel: 'Skip',
            doneLabel: 'Done',
            hidePrev: false,
            hideNext: false,
            tooltipPosition: 'bottom',
            tooltipClass: '',
            highlightClass: '',
            exitOnEsc: true,
            exitOnOverlayClick: true,
            showStepNumbers: true,
            keyboardNavigation: true,
            showButtons: true,
            showBullets: true,
            showProgress: false,
            scrollToElement: true,
            overlayOpacity: 0,
            scrollPadding: 60,
            positionPrecedence: ["bottom", "top", "right", "left"],
            disableInteraction: true,
            hintPosition: 'top-middle',
            hintButtonLabel: 'Got it',
            hintAnimation: true
        });
        intro.start();
    }

    getTourByUrl(url: string): string {
        for (let tour in this.urls) {
            if (this.urls[tour].indexOf(url) !== -1) {
                return tour;
            }
        }
        return '';
    }

    urls: TourUrls = {
        testCase: ['/live-testing'],
        testCaseManual: ['/manual-testing'],
        supplierCreate: ['/suppliers/create'],
        connectionCreate: ['/sessions/create'],
    };
    collection: ToursCollection = {
        testCase: [
            {
                element: '.intro-test-case-sender',
                intro: '<h4>Select sender ID</h4>Select from a template or type a sender ID from which you would like to send test SMS.'
            }, {
                element: '.intro-test-case-text',
                intro: '<h4>Select content</h4>Select from a template or type content which you would like to test.'
            }, {
                element: '.intro-test-case-countries',
                intro: '<h4>Select network</h4>Select the country and network which you would like to test.'
            }, {
                element: '.intro-test-case-connections',
                intro: '<h4>Select supplier</h4>Select a supplier which you would like to test.'
            }, {
                element: '.intro-test-case-run',
                intro: 'Run the test.'
            }, {
                element: '.intro-test-case-result-table',
                intro: '<h4>Check the test results</h4>You can now see the test results. If you need more information about columns, such as DLR/Receipt status, you can always hover over it and see explanation. Fields, such as Sender ID, Text and SMSC are also clickable.'
            }, {
                element: '.intro-info-button',
                intro: 'For more information, please click the information button.'
            }
        ],
        testCaseManual: [
            {
                element: '.intro-test-case-manual-countries',
                intro: '<h4>Select network</h4>Select the country and network which you would like to test.'
            }, {
                element: '.intro-test-case-run',
                intro: '<h4>Run</h4>Click Run to request a test number.'
            }, {
                element: '.intro-test-case-run',
                intro: '<h4>Send test</h4>Send test message with Test ID text in the body of the message on the provided phone number.'
            }, {
                element: '.intro-test-case-result-table',
                intro: '<h4>Wait for test results</h4>Your test result will display automatically if the message arrives on our test number with your Test ID text.'
            }, {
                element: '.intro-info-button',
                intro: 'For more information, please click the information button.'
            }
        ],
        supplierCreate: [
            {
                element: '.intro-suppliers-title-route-type',
                intro: '<h4>Enter Title and Route type</h4>Enter the name of your supplier and route type (Direct, Wholesale, ss7, SIM etc)'
            }, {
                element: '.intro-suppliers-connections',
                intro: '<h4>Link it with a connection</h4>You can either link the supplier directly with an SMPP bind previously created or you can save the changes without linking supplier to an SMPP connection and to it later. '
            }, {
                element: '.intro-suppliers-apply',
                intro: 'Click "Apply".'
            }
        ],
        connectionCreate: [
            {
                intro: '<h4>Create a client account</h4>Create a client account in your system, whitelist our IPs: 52.29.63.229 and set up routing to the supplier which you would like to test'
            }, {
                element: '.intro-connections-host',
                intro: '<h4>Connect to the client account</h4>Enter username and password of the account which you have created and connect to your system\'s Host and Port.'
            }, {
                element: '.intro-connections-suppliers',
                intro: '<h4>Link it with a supplier</h4> You can either link the connection directly with a previously created supplier or you can save the changes and link at a later stage.'
            }, {
                element: '.intro-connections-apply',
                intro: 'Click "Apply".'
            }, {
                element: '.intro-info-button',
                intro: 'For more information, please click the information button.'
            }
        ]
    };
    tourStepsCollectionName: string;
    tourShowButton: boolean = false;
}

interface TourUrls {
    [name: string]: string[];
}

interface ToursCollection {
    [name: string]: TourDefinition[];
}

interface TourDefinition {
    element?: string;
    intro: string;
}