import { Component, HostListener, TemplateRef, ViewChild } from "@angular/core";
import {DialogRef, ModalService} from "../../shared/services/modal.service";

@Component({
    selector: 'app-offline',
    templateUrl: './offline.component.html'
})

export class OfflineComponent {

    offlineModal: DialogRef;
    @ViewChild('offlineModalTpl', { read: TemplateRef, static: false }) offlineModalTpl;

    constructor(public modal: ModalService) {}

    @HostListener('window:online', ['$event'])
    onOnline() {
        if (!this.offlineModal) {
            return;
        }
        this.offlineModal.close(true);
        this.offlineModal = null;
    }

    @HostListener('window:offline', ['$event'])
    onOffline() {
        if (this.offlineModal) {
            return;
        }
        this.offlineModal = this.modal.alert()
            .dialogClass('modal-dialog modal-transparent')
            .isBlocking(true)
            .component(this.offlineModalTpl)
            .open();
    }
}