import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { RouteView, RoutingUpdate } from "../../shared/models/routing.model";
import { RoutingService } from "../../shared/services/routing.service";
import { NetworksSelectComponent } from "../../shared/components/networks-select/networks-select.component";
import { RoutesSelectComponent } from "../routes-select/routes-select.component";

@Component({
  selector: 'app-routing-form',
  templateUrl: './routing-form.component.html',
  styleUrls: ['./routing-form.component.scss']
})
export class RoutingFormComponent implements OnInit {

    @Input() model: RoutingUpdate;
    @Output() onAfterSave = new EventEmitter<RoutingUpdate>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;
    disabledNetworks = false;

    @ViewChild(NetworksSelectComponent, { static: false }) networksSelectComponent: NetworksSelectComponent;
    @ViewChild(RoutesSelectComponent, { static: false }) routesSelectComponent: RoutesSelectComponent;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private routingService: RoutingService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            networkId: [this.model.networkId, V.compose([V.required])],
            productId: [this.model.routes.length ? this.model.routes[0].productId : null, V.compose([V.required])],
        });
        this.disabledNetworks = !!this.model.networkId;
    }

    onSelectNetworks(networkIds: number[]) {
        this.model.networkId = networkIds.length ? networkIds[0] : null;
        this.form.controls.networkId.patchValue(networkIds.length ? networkIds[0] : null);
    }

    onSelectProducts(products: RouteView[]) {
        if (!products.length) {
            this.model.routes = [];
            this.form.controls.productId.patchValue(null);
            return;
        }
        this.form.controls.productId.patchValue(products[0].productId);
        this.model.routes = [
            {
                id: null,
                productId: products[0].productId,
                routeChannel: products[0].routeChannel
            }
        ];
    }

    onSave() {
        this.loading = true;
        this.routingService.save(this.model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(r=> {
            this.loading = false;
            this.notifications.success('Routing ' + (this.model.id ? 'updated' : 'created'), 'Routing');
            this.onAfterSave.emit(r);
        });
    }

}
