import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thousandseparate'
})
export class ThousandSeparator implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value != null) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
        return value;
    }
}
