import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { SessionsTableActionFun } from "./sessions-table.component";
import { Session } from "../../shared/models/session.model";

@Component({
    selector: 'app-sessions-table-actions-renderer',
    template: `
        <div *ngIf="params" class="h-100 d-flex justify-content-around align-items-center gap-0_5">
            <i class="cp icon-edit" tooltip="Edit" (click)="params.onAction('edit', params)"></i>
            <i class="cp icon-delete" tooltip="Delete" (click)="params.onAction('delete', params)"></i>
        </div>
    `,
})
export class SessionsTableActionsRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams<Session> & {onAction: SessionsTableActionFun};

    agInit(params: ICellRendererParams<Session> & {onAction: SessionsTableActionFun}) {
        this.params = params;
    }

    refresh(params: ICellRendererParams<Session>): boolean {
        return false;
    }
}