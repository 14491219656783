import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { CountriesTableActionsRendererComponent } from "./countries-table-actions-renderer.component";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { Country } from "../../shared/models/network.model";
import { NetworkService } from "../../shared/services/network.service";

@Component({
    selector: 'app-countries-table',
    templateUrl: './countries-table.component.html',
    styleUrls: [],
})
export class CountriesTableComponent implements OnInit, OnDestroy {

    theme = AgGridUtils.theme;
    gridApi: GridApi<Country>;

    gridOptions: GridOptions<Country> = {
        onGridReady: e => {
            this.gridApi = e.api;
            this.update();
        },
        columnDefs: [
            {
                headerName: 'Name',
                valueGetter: (params) => params.data.name,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                resizable: false,
                getQuickFilterText: params => params.data.name
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: CountriesTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: CountriesTableAction, params: ICellRendererParams<Country>) => this.onAction(action, params),
                },
                getQuickFilterText: params => '',
            }
        ],
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    };

    rows: Country[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('orgFormModalTpl', { read: TemplateRef, static: false }) orgFormModalTpl: any;
    countryModel: Country;
    countryFormModal: DialogRef;

    @Input() selectMode = false;

    constructor(
        private userService: UserService,
        private networkService: NetworkService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit() {

    }

    update() {
        this.loading = true;
        this.$update = this.networkService.allCountries()
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                this.rows = rows;
                this.loading = false;
            });
    }

    private onAction(action: CountriesTableAction, params: ICellRendererParams<Country>) {
        if (action === 'edit') {
            this.countryModel = Object.assign({}, params.data);
            this.countryFormModal = this.modalService.alert().isBlocking(true).component(this.orgFormModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.networkService.deleteCountry(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Country deleted', 'Networks');
                    this.update();
                });
            });
        }
    }

    onAfterSave() {
        this.countryFormModal.close();
        this.update();
    }

    onCreateCountry() {
        this.countryModel = {
            id: null,
            name: '',
            isoAlpha2: null,
            isoAlpha3: null,
            countryCallingCode: '',
            mnpSupported: null,
        }
        this.countryFormModal = this.modalService.alert().isBlocking(true).component(this.orgFormModalTpl).open();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type CountriesTableAction = 'edit'|'delete';
export type CountriesTableActionFun = (action: CountriesTableAction, params: ICellRendererParams<Country>) => void;