
import { Component, Input } from '@angular/core';

@Component({
    selector: 'form-errors',
    templateUrl: './formErrors.component.html',
    styleUrls: ['./formErrors.component.scss']
})
export class FormErrors {
    @Input() errors: {field: string, message: string}[] = [];
}