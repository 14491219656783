
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';

@Component({
    selector: 'input-file',
    templateUrl: './input.file.html',
    styleUrls: ['./input.file.scss']
})

export class InputFile {

    @Input() maxFiles: number = 1;
    @Input() accept: string[] = [];
    @Input() acceptExtensions: string[] = [];
    @Input() maxSize: number = 0;

    @Output() change: EventEmitter<any> = new EventEmitter();

    @ViewChild('inputFile', {static: false}) inputFile: ElementRef;

    control: FormControl = new FormControl();
    files: File[] = [];
    isDragOver: boolean = false;

    constructor(public validationService: ValidationService) {}

    onChangeInput(list: FileList) {
        if (!list.length) {return;}
        let isValid = false;
        for (let i = 0; i < list.length; i++) {
            isValid = this.validationService.validateFile(list.item(i), this.control, this.maxSize, this.acceptExtensions);
            if (!isValid) {
                break;
            }
        }

        if (isValid) {
            if (this.maxFiles === 1) {
                this.files = [];
            }
            for (let i = 0; i < list.length; i++) {
                if (this.files.length >= this.maxFiles) {
                    continue;
                }
                this.files.push(list.item(i));
            }
        }

        this.inputFile.nativeElement.value = '';
        this.change.emit(this.files);
    }

    onClickRemoveFile(file: File) {
        this.files = this.files.filter(_ => _ !== file);
        this.change.emit(this.files);
    }

    onClickButtonSelectFile() {
        this.inputFile.nativeElement.click();
    }

    @HostListener('dragover', ['$event']) onDragOver(evt){
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = true;
    }
    @HostListener('dragleave', ['$event']) onDragLeave(evt){
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = false;
    }
    @HostListener('drop', ['$event']) onDrop(evt){
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if(files.length){
            this.onChangeInput(files);
        }
        this.isDragOver = false;
    }
}