import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import { GridApi, GridOptions, ICellRendererParams } from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { PrefixesTableActionsRendererComponent } from "./prefixes-table-actions-renderer.component";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { NetworkUpdate, Prefix } from "../../shared/models/network.model";
import { NetworkService } from "../../shared/services/network.service";
import { Role } from "../../shared/models/user.model";

@Component({
    selector: 'app-prefixes-table',
    templateUrl: './prefixes-table.component.html',
    styleUrls: [],
})
export class PrefixesTableComponent implements OnInit, OnDestroy {

    theme = AgGridUtils.theme;
    gridApi: GridApi<Prefix>;

    gridOptions: GridOptions<Prefix> = {
        onGridReady: e => {
            this.gridApi = e.api;
            this.update();
        },
        columnDefs: [
            {
                headerName: 'Prefix',
                valueGetter: (params) => params.data.prefix,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                resizable: false,
                getQuickFilterText: params => params.data.prefix
            },
            {
                colId: 'actions',
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: PrefixesTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: PrefixesTableAction, params: ICellRendererParams<Prefix>) => this.onAction(action, params),
                },
                getQuickFilterText: params => ''
            }
        ],
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    };

    isAdmin = false;

    rows: Prefix[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('prefixFormModalTpl', { read: TemplateRef, static: false }) prefixFormModalTpl: any;
    prefixModel: Prefix;
    prefixFormModal: DialogRef;

    @Input() network: NetworkUpdate;

    constructor(
        private userService: UserService,
        private networkService: NetworkService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit() {
        this.isAdmin = this.userService.user.value.roles.includes(Role.ADMIN);
        if (!this.isAdmin) {
            this.gridOptions.columnDefs = [this.gridOptions.columnDefs[0]];
        }
    }

    update() {
        this.loading = true;
        this.$update = this.networkService.allPrefixesByNetworkId(this.network.id, 0)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                this.rows = rows;
                this.loading = false;
            });
    }

    private onAction(action: PrefixesTableAction, params: ICellRendererParams<Prefix>) {
        if (action === 'edit') {
            this.prefixModel = Object.assign({}, params.data);
            this.prefixFormModal = this.modalService.alert().isBlocking(true).component(this.prefixFormModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.networkService.deletePrefix(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Prefix deleted', 'Networks');
                    this.update();
                });
            });
        }
    }

    onAfterSave() {
        this.prefixFormModal.close();
        this.update();
    }

    onCreatePrefix() {
        this.prefixModel = {
            id: null,
            prefix: '',
            mcc: this.network.mcc,
            mnc: this.network.mnc
        }
        this.prefixFormModal = this.modalService.alert().isBlocking(true).component(this.prefixFormModalTpl).open();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type PrefixesTableAction = 'edit'|'delete';
export type PrefixesTableActionFun = (action: PrefixesTableAction, params: ICellRendererParams<Prefix>) => void;