import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { Supplier, SupplierAllRequestParams } from "../models/supplier.model";

@Injectable({
    providedIn: 'root'
})
export class SupplierService {

    constructor( private http: HttpClient) {}

    all(params: SupplierAllRequestParams): Observable<Page<Supplier>> {
        const query = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/routing/%ROLE%/suppliers`;
        return this.http.get<ApiResponse<Page<Supplier>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: Supplier): Observable<void> {
        if (model.id) {
            const url = `%BASE_URL%/routing/%ROLE%/suppliers/${model.id}`;
            return this.http.put<void>(url, model,{withCredentials: true});
        }
        const url = `%BASE_URL%/routing/%ROLE%/suppliers`;
        return this.http.post<void>(url, model,{withCredentials: true});
    }

    delete(id: number): Observable<void> {
        const url = `%BASE_URL%/routing/%ROLE%/suppliers/${id}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): Supplier {
        return {
            id: null,
            name: '',
        };
    }

}
