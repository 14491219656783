import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, skipWhile } from "rxjs";
import { UserService } from "./user.service";
import { Role } from "../models/user.model";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {

    private role: Role;

    constructor(@Inject('API_URL') private baseUrl: string, private userService: UserService) {
        userService.user.pipe(skipWhile(val => !val)).subscribe(user => {
            if (user.roles.includes(Role.ADMIN)) {
                this.role = Role.ADMIN;
            } else if (user.roles.includes(Role.USER)) {
                this.role = Role.USER;
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone({
            url: this.replaceBaseUrl(this.replaceRole(req.url))
        });
        return next.handle(newReq);
    }

    private replaceBaseUrl(url: string|null): string|null {
        if (url === null) {return url;}
        return url.replace('%BASE_URL%', this.baseUrl);
    }

    private replaceRole(url: string|null): string|null {
        if (url === null || !this.role) {return url;}
        const role = this.role === Role.ADMIN ? 'admin' : 'client';
        return url.replace('%ROLE%', role);
    }

}