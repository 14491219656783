import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { RouteView, RoutingView, RoutingAllRequestParams, RoutingUpdate } from "../models/routing.model";
import { NetworkDetails } from "../models/network.model";

@Injectable({
    providedIn: 'root'
})
export class RoutingService {

    constructor(private http: HttpClient) {}

    all(params: RoutingAllRequestParams): Observable<Page<RoutingView>> {
        const query = {
            page: params.page,
            size: params.size,
            networksIds: params.networksIds.length ? params.networksIds.join(',') : '',
            productsIds: params.productsIds.length ? params.productsIds.join(',') : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/routing/%ROLE%/routing`;
        return this.http.get<ApiResponse<Page<RoutingView>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: RoutingUpdate): Observable<RoutingUpdate> {
        if (model.id) {
            const url = `%BASE_URL%/routing/%ROLE%/routing/${model.id}`;
            return this.http.put<ApiResponse<RoutingUpdate>>(url, model,{withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        const url = `%BASE_URL%/routing/%ROLE%/routing`;
        return this.http.post<ApiResponse<RoutingUpdate>>(url, model,{withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/routing/%ROLE%/routing?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): RoutingUpdate {
        return {
            id: null,
            name: '',
            routingType: 'STATIC',
            networkId: null,
            routes: []
        };
    }

    networks(): Observable<NetworkDetails[]> {
        const url = `%BASE_URL%/routing/%ROLE%/coverages/networks`;
        return this.http.get<ApiResponse<NetworkDetails[]>>(url, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    routes(networkId?: number): Observable<RouteView[]> {
        const url = `%BASE_URL%/routing/%ROLE%/coverages/routes`;
        let query = {};
        if (networkId) {query['networkId'] = networkId;}
        return this.http.get<ApiResponse<RouteView[]>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

}


