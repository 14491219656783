import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { BrowserUtils } from "../shared/services/browser-utils";
import { UserService } from "../shared/services/user.service";
import { CurrentUser } from "../shared/models/user.model";

@Component({
    selector: 'app-component',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {

    @ViewChild(MainMenuComponent, { static: false }) mainMenu: MainMenuComponent;

    isAuth: boolean = false;
    user: CurrentUser;

    constructor(
        private router: Router,
        private userService: UserService,
        private vc: ViewContainerRef,
    ) {
        userService.isAuth.subscribe(_ => this.isAuth = _);
        userService.user.subscribe(_ => this.user = _);
    }

    ngOnInit() {
        BrowserUtils.initPlugins();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        BrowserUtils.isUnload = true;
    }

    logout() {


    }

}
